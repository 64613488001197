import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ResizableBox } from 'react-resizable';
import { Box } from '@mui/material';

import { throttle, debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';

import EditorTopBar from './EditorTopBar';
import { VideoLayers, VideoLayersRef, MediaLayer } from './VideoLayers';
import { serializeSegments, Segment } from '../utils/serializer';
import EffectSelector from './EffectSelector';
import Video from './media/Video';
import Audio from './media/Audio';
import TextMedia from './media/Text';
import ImageMedia from './media/Image';
import Media from './media/Media';
import { downloadVideo, getRenderTasks } from '../api/ServerApi';
import { pollRenderedVideoAndDownload, taskCompleteStatuses } from '../utils/utils';
import CommandHistory, { AddVideoEffectCommand, SelectVideoCommand, VideoAnimationChangeDimensionsCommand, AddVideoCommand, ChangeApsectRatioCommand, debounceBackgroundChangeHistoryAction } from './undo/UndoInterface';
import RotatableBox, { DirectionType } from './RotatableBox';

import getEngine from './EffectsEngine';

import 'react-resizable/css/styles.css';

const Engine = await getEngine();

let gl: WebGLRenderingContext | null;
let program: WebGLProgram;
let texture: WebGLTexture | null;

function initWebGLCanvas(canvas: HTMLCanvasElement): void {
    gl = canvas.getContext('webgl', {
      alpha: true,
      premultipliedAlpha: true
    });
    if (!gl) {
        throw new Error('WebGL not supported');
    }

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    const vertexShaderSource = `
        attribute vec2 a_position;
        attribute vec2 a_texCoord;
        varying vec2 v_texCoord;
        uniform vec2 u_translation;
        uniform vec2 u_scale;
        void main() {
            vec2 scaledPosition = (a_position * u_scale) + u_translation;
            gl_Position = vec4(scaledPosition, 0.0, 1.0);
            v_texCoord = a_texCoord;
        }
    `;

    const fragmentShaderSource = `
        precision mediump float;
        varying vec2 v_texCoord;
        uniform sampler2D u_texture;
        void main() {
            //vec2 flippedTexCoord = vec2(v_texCoord.x, 1.0 - v_texCoord.y);
            //vec4 color = texture2D(u_texture, flippedTexCoord);
            //gl_FragColor = color;

            gl_FragColor = texture2D(u_texture, v_texCoord);
        }
    `;

    program = createShaderProgram(gl, vertexShaderSource, fragmentShaderSource);
    gl.useProgram(program);

    // Setup position buffer
    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    const positions = new Float32Array([
        -1.0, -1.0, // Bottom left
         1.0, -1.0, // Bottom right
        -1.0,  1.0, // Top left
         1.0,  1.0  // Top right
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);

    const positionLocation = gl.getAttribLocation(program, 'a_position');
    gl.enableVertexAttribArray(positionLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

    // Setup texture coordinate buffer
    const texCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
    const texCoords = new Float32Array([
        0.0, 1.0,
        1.0, 1.0,
        0.0, 0.0,
        1.0, 0.0
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, texCoords, gl.STATIC_DRAW);

    const texCoordLocation = gl.getAttribLocation(program, 'a_texCoord');
    gl.enableVertexAttribArray(texCoordLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
    gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);

    texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    const textureLocation = gl.getUniformLocation(program, 'u_texture');
    gl.uniform1i(textureLocation, 0);
}

function createShaderProgram(gl: WebGLRenderingContext, vertexShaderSource: string, fragmentShaderSource: string): any {
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
    const shaderProgram:any = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        console.error('Unable to initialize the shader program:', gl.getProgramInfoLog(shaderProgram));
        return null;
    }

    return shaderProgram;
}

function loadShader(gl: WebGLRenderingContext, type: number, source: string): any {
    const shader:any = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('An error occurred compiling the shaders:', gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }

    return shader;
}

let framebuffer: WebGLFramebuffer | null = null;
function setupFramebuffer(width: number, height: number) {
    // Create the framebuffer if it doesn't exist
    if (!framebuffer && gl) {
        framebuffer = gl.createFramebuffer();

        // Create a texture to attach to the framebuffer
        const framebufferTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, framebufferTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

        // Bind the framebuffer and attach the texture
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, framebufferTexture, 0);

        // Check if the framebuffer is complete
        if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE) {
            throw new Error('Framebuffer is not complete');
        }

        // Unbind the framebuffer for now
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    }
}

function renderVideoFrameGL(
  dataSource: HTMLVideoElement | Uint8Array | HTMLCanvasElement,
  x: number,
  y: number,
  width: number,
  height: number,
  canvas: any,
  out: Uint8Array | null,
  renderOffscreen: boolean = false,
  crop: any = {top: 0, bottom: 0, left: 0, right: 0},
): void {
  if (!gl || !texture) {
      throw new Error('WebGL not initialized');
  }

  // Toggle between rendering to framebuffer or canvas
  if (renderOffscreen && framebuffer) {
      gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
  } else {
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

  if (dataSource instanceof Uint8Array) {
      gl.texImage2D(
          gl.TEXTURE_2D,       // Target
          0,                   // Level of detail
          gl.RGBA,             // Internal format
          width,               // Width of the texture
          height,              // Height of the texture
          0,                   // Border (must be 0)
          gl.RGBA,             // Format
          gl.UNSIGNED_BYTE,    // Type of the data
          dataSource           // Pixel data
      );
    } else if (dataSource instanceof HTMLVideoElement || dataSource instanceof HTMLCanvasElement) {
      // dataSource is an HTMLVideoElement
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, dataSource);
  } else {
      // we cannot run this type
      return;
  }

  // Calculate cropped width and height
  const croppedWidth = width - crop.left - crop.right;
  const croppedHeight = height - crop.top - crop.bottom;

  // Calculate texture coordinates to only render the cropped area
  // Adjusted to flip the image vertically (flip Y-coordinates)
  const textureCoords = [
      crop.left / width, (height - crop.top) / height,   // Top-left (flipped Y)
      (width - crop.right) / width, (height - crop.top) / height,   // Top-right (flipped Y)
      crop.left / width, crop.bottom / height,   // Bottom-left (flipped Y)
      (width - crop.right) / width, crop.bottom / height  // Bottom-right (flipped Y)
  ];

  // Bind buffer data (assuming the vertex positions are already set up)
  const texCoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoords), gl.STATIC_DRAW);

  // Enable the texture coordinates attribute in your shader program
  const texCoordLocation = gl.getAttribLocation(program, 'a_texCoord');
  gl.enableVertexAttribArray(texCoordLocation);
  gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);

  // Update the uniform for position and size to fill the cropped area
  const translationLocation: WebGLUniformLocation | null = gl.getUniformLocation(program, 'u_translation');
  const scaleLocation: WebGLUniformLocation | null = gl.getUniformLocation(program, 'u_scale');

  gl.uniform2f(
      translationLocation,
      ((2 * (x + croppedWidth / 2)) / canvas.width) - 1,
      1 - ((2 * (y + croppedHeight / 2)) / canvas.height)
  );

  gl.uniform2f(scaleLocation, croppedWidth / canvas.width, croppedHeight / canvas.height);

  // Draw the rectangle with the updated texture coordinates
  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

  // Optionally read the pixels
  if (out) {
      gl.readPixels(x, canvas.height - height - y, croppedWidth, croppedHeight, gl.RGBA, gl.UNSIGNED_BYTE, out);
  }
}

const initialAspectRatio = 16/9;
const Home: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(0);
  const [selectedVideoLayer, setSelectedVideoLayer] = useState<Media | null>(null);
  const [videos, setVideosState] = useState<Video[]>([]);
  const [isRunning, setIsRunning] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [renderPending, setRenderPending] = useState(0);
  const [canvasAspectRatio, setCanvasAspectRatio] = useState(initialAspectRatio);
  const [totalDuration, setTotalDuration] = useState(0);
  const [totalTracksDuration, setTotalTracksDuration] = useState(0);
  const [rotatableBoxMultiplier, setRotatableBoxMultiplier] = useState({width: 1, height: 1});
  const [initialHeight, setInitialHeight] = useState<number>(270);
  const [initialWidth, setInitialWidth] = useState<number>(270);
  const [canvasHeight, setCanvasHeight] = useState(initialHeight);
  const [canvasWidth, setCanvasWidth] = useState(initialWidth);
  const [maxPageHeight, setMaxPageHeight] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [canvasBackground, setCanvasBackground] = useState<[number, number, number, number]>([0, 0, 0, 1.0]);
  const videoLayersRef = useRef<VideoLayersRef>(null);
  const videoSegments = useRef<Segment[]>([]);
  const videosRefs = useRef<Video[]>([]);
  const resizableParentRef = useRef<any>();
  const moveableBoxRef = useRef<any>(null);
  const renderCanvas = useRef<any>(null);
 // const canvasBackground = useRef<[number, number, number, number]>([0, 0, 0, 1.0]);
  const draftCanvas = useRef<any>(null);
  var shouldRender = useRef<boolean>(false);
  const playStartTime = useRef<number | null>(0);
  const playStartBase = useRef<number | null>(0);
  const animationId = useRef<any>(null);

  const numColumns = 5192;
  const topNavbarHeight = 48;
  const videoDownloadWidth = 640;
  const initialVideoControlsHeight = 280;
  // TODO: this is not passed to the actual element - it's not synced! need to sync them
  const minEffectSelectorSize = 500;
  const sliderThrottleTime = 66;

  const isMobileLayout = () => {
    return window.innerWidth < 768;
  }

  const checkAndSetMobileLayout = () => {
    setMaxPageHeight(window.innerHeight - topNavbarHeight);
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      return false;
    }
  };

  const setVideos = (newVideos: Media[]) => {
    setVideosState(newVideos as Video[]);
    videosRefs.current = newVideos as Video[];
  }

  const downloadPendingVideosIfExist = () => {
    getRenderTasks().then((videosInfo) => {
      if (videosInfo === null) {
          // if there are no pending videos
          return;
      }
      const pendingVideos = videosInfo.filter((vid: any) => !taskCompleteStatuses.includes(vid.status));
      if (pendingVideos.length > 0) {
        setIsDownloading(true);
      }
      for (const video of pendingVideos) {
        pollRenderedVideoAndDownload(video.fileName, 1000, () => {
          // TODO: if we have multiple pending videos, the animation will be canceled by first...
          // Right now this shouldn't even happen as we limit to one video at a time
          setIsDownloading(false);
        });
      }
    })
  }

  useEffect(() => {
    Engine.on('log', (message: any) => {
        console.log(message);
    });

    // set this for choosing the layout type - mobile or desktop
    checkAndSetMobileLayout();

    const effectSelectorSpace = isMobileLayout() ? 20 : minEffectSelectorSize;

    let cheight = Math.min(window.innerHeight - initialVideoControlsHeight - 150, 270*2); // -100 for the top bars... careful to change this as it messes up the box
    let cwidth = cheight * initialAspectRatio;
    if (cwidth > window.innerWidth - effectSelectorSpace) {
      cwidth = Math.max(Math.min(window.innerWidth - effectSelectorSpace, 270*3), 100);
      cheight = cwidth / initialAspectRatio;
    }
    setInitialHeight(cheight);
    setInitialWidth(cwidth);
    setCanvasHeight(cheight);
    setCanvasWidth(cwidth);

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'z') {
        event.preventDefault(); // Prevent the default browser action (e.g., undo in a text input)
        CommandHistory.undo();
      }

      if (event.ctrlKey && event.key === 'r') {
        event.preventDefault(); // Prevent the default browser action (e.g., reload page)
        CommandHistory.redo();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('resize', checkAndSetMobileLayout);

    renderCanvas.current.height = cheight;
    renderCanvas.current.width = cwidth * canvasAspectRatio;
    draftCanvas.current.height = cheight;
    draftCanvas.current.width = cwidth * canvasAspectRatio;
    initWebGLCanvas(renderCanvas.current);
    setupFramebuffer(cwidth, cheight);

    if (gl) {
      gl.clearColor(...canvasBackground);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }

    downloadPendingVideosIfExist();

    // prevent IOS back command when swiping from the left
    const handleTouchEvent = (event: TouchEvent) => {
      // Prevent swipe-back on iOS if the touch starts near the left edge (within 50px)
      if (event.touches[0].clientX < 50) {
        event.preventDefault();
      }
    };
    //window.addEventListener('touchstart', handleTouchEvent, { passive: false });
    window.addEventListener('touchmove', handleTouchEvent, { passive: false });

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', checkAndSetMobileLayout);
      window.removeEventListener('touchstart', handleTouchEvent);
      //window.removeEventListener('touchmove', handleTouchEvent);
    }
  },[]);

  useEffect(() => {
    if (gl) {
      gl.clearColor(...canvasBackground);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }
    renderOneFrame(currentTime, true, true);
  }, [videoSegments])

  useEffect(() => {
    renderOneFrame(renderPending, true, true);
  }, [renderPending])
  useEffect(() => {
    renderOneFrame(renderPending, false, true);
  }, [canvasBackground])

  useEffect(() => {
    // TODO: need to redesign this - should not keep the segments in a different unsynced variable. has to be 100% synced
    // and i don't like the way it is synced here (slow operation every change)
    // videos have changed, need to sync the videoSegments which are used to render the videos
    videoSegments.current.forEach((segment: Segment) => {
      segment.layers.forEach((layer: MediaLayer) => {
        const videoIndex = videos.findIndex((video) => video.id === layer.video.id);
        if (videoIndex !== -1) {
          layer.video = videos[videoIndex];
        } else {
          layer.video.videoRef = null;
        }
      })
    })

    // need to sync the videolayer as it may have changed
    const videoIndex = videos.findIndex((video) => video.id === selectedVideoLayer?.id);
    if (videoIndex !== -1) {
      setSelectedVideoLayer(videos[videoIndex]);
    }
    renderOneFrame(currentTime, false, true);
  }, [videos])

  useEffect(() => {
    setCanvasWidth(Math.floor(initialHeight * canvasAspectRatio));
    if (renderCanvas.current) {
      renderCanvas.current.height = initialHeight;
      renderCanvas.current.width = initialHeight * canvasAspectRatio;
      draftCanvas.current.height = initialHeight;
      draftCanvas.current.width = initialHeight * canvasAspectRatio;
      // Update the WebGL viewport to match the new canvas size
      if (gl) {
        // set the viewport for both the framebuffer and the real screen
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
        gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

        gl.clearColor(...canvasBackground);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }
    }

    renderOneFrame(currentTime, false, true);
  }, [canvasAspectRatio, canvasHeight, canvasWidth])

  const getActiveSegment = (segments: any[], seconds: number) => {
    const candidateSegments = segments.filter((layer: any) => {
      return (layer.start <= seconds && layer.end >= seconds);
    })
    let curSegment: any = null;
    if (candidateSegments.length) {
      curSegment = candidateSegments[0];
    }
    return curSegment;
  }

  const getActiveVideo = (segments: any[], seconds: number) => {
    let activeVideo = null;
    const activeSegment = getActiveSegment(segments, seconds);
    if (activeSegment && activeSegment.layers.length) {
      activeVideo = activeSegment.layers[0].video;
    }
    return activeVideo;
  }

  function waitForSeek(video: HTMLVideoElement): Promise<void> {
    return new Promise((resolve) => {
        if (!video) {
          resolve();
        }
        video.onseeked = () => {
            video.onseeked = null;
            resolve();
        };
    });
  }

  const getVideoTime = (layer: any, seconds: number) => (seconds - layer.start + layer.playOffset) * layer.video.speed;

  const renderSubtitles = (video: Video) => {
    const videoElement = video.videoRef;
    const textTrack = videoElement.textTracks[0];
    const activeCues = textTrack.activeCues;
    if (activeCues && activeCues.length > 0) {
      const cue = activeCues[0] as VTTCue; // Active subtitle cue
      createTextImage(cue.text, "12px Arial", 'white', video.x, video.y, (video.scaledWidth as number), (video.scaledHeight as number), 14);
    }
  }

  const renderOneFrame = async (seconds: number, shouldSeek: boolean = false, reApplyFilters: boolean = false) => {
    const curSegment = getActiveSegment(videoSegments.current, seconds);

    if (curSegment && curSegment.layers.length && shouldSeek) {
      const seekedLayers = curSegment.layers.filter((layer: any) => (layer.video instanceof Video || layer.video instanceof Audio) && layer.video.videoRef && layer.video.videoRef.currentTime !== getVideoTime(layer, seconds));

      seekedLayers.forEach((layer: any) => layer.video.videoRef.currentTime = getVideoTime(layer, seconds));
      const seekPromises = seekedLayers.map((layer: any) => waitForSeek(layer.video.videoRef));

      await Promise.all(seekPromises);
    }

    const mediaWithVideo = curSegment && curSegment.layers.filter((layer: any) => layer.video.hasVideo());
    if (!mediaWithVideo || !mediaWithVideo.length) {
      if (gl) {
        gl.clearColor(...canvasBackground);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }
      return false;
    }

    if (reApplyFilters) {
      const renderVideos = curSegment.layers.map((layer: any) => layer.video);
      const validVideos = renderVideos.filter((video: Video) => video.videoRef !== null && video.hasVideo())
      if (validVideos.length) {
        await renderVideosWithFilter(validVideos);
      }
    } else {
      if (gl) {
        gl.clearColor(...canvasBackground);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }

      for (const layer of curSegment.layers) {
        const curVideo = layer.video;

        if (!curVideo || !curVideo.videoRef || !curVideo.hasVideo()) {
          return;
        }

        renderVideoFrameGL(curVideo.videoRef, curVideo.x, curVideo.y, curVideo.scaledWidth, curVideo.scaledHeight, renderCanvas.current, null);

        if (curVideo.subtitlesActive && curVideo.videoRef.textTracks.length) {
          renderSubtitles(curVideo);
        }
      }
    }
  }

  const requiresWasm = (media: Media) => {
    return media.requiresWasm();
  }

  const renderVideosWithFilter = async (renderVideos: Video[]): Promise<void>  => {
    return new Promise((resolve) => {
      for (const curVideo of renderVideos) {
        if (!requiresWasm(curVideo)) {
          // dont need the pixels here - we will render the video directly later
          continue;
        }
        if (!curVideo.storageBuffer || curVideo.storageBuffer.length !== (curVideo.scaledWidth as number) * (curVideo.scaledHeight as number) * 4) {
          curVideo.storageBuffer = new Uint8Array((curVideo.scaledWidth as number)  * (curVideo.scaledHeight as number) * 4);
        }

        if (!curVideo.videoRef) {
          // this can happen if the video hasn't finished loading yet
          continue;
        }

        // TODO: it seems i cant get the pixels out if i call getPixels outside if i dont pass "out" here - not sure why
        renderVideoFrameGL(curVideo.videoRef, curVideo.x, curVideo.y, curVideo.scaledWidth as number, curVideo.scaledHeight as number, renderCanvas.current, curVideo.storageBuffer, true);
      }

      if (gl && renderVideos.length) {
        // run wams only on frames that have filters or crops
        const filterVideos = renderVideos.filter((vid: Video) => requiresWasm(vid));
        const frames = filterVideos.map((vid: Video) => vid.storageBuffer as Uint8Array);
        const args = filterVideos.map((vid: Video) => [vid.videoFdRef, vid.scaledWidth, vid.scaledHeight]);
        //const args = renderVideos.map((vid: Video) => [vid.videoFdRef, renderCanvas.current.width, renderCanvas.current.height]);
        return resolve(Engine.foo((args as unknown) as string[], frames).then((data: Uint8Array[]) => {
          if (gl) {
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            gl.clearColor(...canvasBackground);
            gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
          }
          let j = 0;
          for (let i = 0; i < renderVideos.length; i++) {
            const video = renderVideos[i];
            if (requiresWasm(video)) {
              video.storageBuffer = data[j++]; // re assign the storage buffer - cant use it again anyway
              renderVideoFrameGL(video.storageBuffer as Uint8Array, video.x, video.y, video.scaledWidth as number, video.scaledHeight as number, renderCanvas.current, null, false);
            } else {
              const crop = {left: video.crop.left * (video.baseWidth as number), right: video.crop.right * (video.baseWidth as number), bottom: video.crop.top * (video.baseHeight as number), top: video.crop.bottom * (video.baseHeight as number)}
              renderVideoFrameGL(video.videoRef as Uint8Array, video.x, video.y, video.baseWidth as number, video.baseHeight as number, renderCanvas.current, null, false, crop);
            }

            if (video.subtitlesActive && video.videoRef.textTracks.length) {
              renderSubtitles(video);
            }
          }
        }));
      }
      return resolve();
    });
  }

  const throttledTimeChange =
      throttle((time: any) => {
        setCurrentTime(time)
      }, sliderThrottleTime)

  const start = useRef<Date>();
  const numFrames = useRef<number>(0);
  const renderFrame = async () => {
    const renderStart = new Date();
    if (start.current === undefined) {
      start.current = new Date();
    }

    if (!shouldRender.current) {
      return;
    }

    let seconds = 0;
    if (playStartTime.current !== null && playStartBase.current !== null) {
      const playOffset = (((new Date().getTime() / 1000) - playStartTime.current as number));
      seconds = playOffset + playStartBase.current;
      // TODO: This slows us down considerably still
      throttledTimeChange(seconds);
    }

    if (seconds >= totalDuration) {
      videosRefs.current.forEach((video: Video) => {
        if ((!video.videoRef || !(video instanceof Video)) &&
            (!video.videoRef || !(video instanceof Audio))) {
          return;
        }
        video.isPlaying = false;
        video.videoRef.pause()
      });
      shouldRender.current = false;
      setIsRunning(false);
      if (animationId.current) {
        cancelAnimationFrame(animationId.current);
      }
      // we're done playing
      return;
    }

    const curSegment = getActiveSegment(videoSegments.current, seconds);
    const mediaWithVideo = curSegment && curSegment.layers.filter((layer: any) => layer.video.hasVideo());
    if (!mediaWithVideo || !mediaWithVideo.length) {
      // No media with video... paint a black screen
      if (gl) {
        gl.clearColor(...canvasBackground);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }
      if (!curSegment || !curSegment.layers.length) {
        // If there is no media at all - stop running
        // TODO: the slider is getting too many events when we do this, need to buffer
        animationId.current = requestAnimationFrame(renderFrame);
        return;
      }
    }

    const segmentVideos = curSegment.layers.map((layer: any) => layer.video);

    // pause sound of all videos that should not play
    for (let vid of videos) {
      if (segmentVideos.indexOf(vid) === -1){
        if (vid.isPlaying && vid.videoRef) {
          vid.videoRef.pause()
          vid.isPlaying = false;
        }
      }
    }

    // start sound of all videos that should play
    for (const layer of curSegment.layers) {
      const vid = layer.video;
      if (!vid.isPlaying && vid.videoRef && (vid instanceof Video || vid instanceof Audio)) {
        const curVideoTime = getVideoTime(layer, seconds);
        vid.videoRef.currentTime = curVideoTime;
        vid.videoRef.play().then(() => {
          vid.isPlaying = true;
        });
      }
    }

    const renderVideos = curSegment.layers.map((layer: any) => layer.video).filter((vid: Video) => vid.hasVideo());
    await renderVideosWithFilter(renderVideos);

    numFrames.current++;
    if (numFrames.current % 30 === 0)  {
      const timepassed = (new Date().getTime() - (start.current as Date).getTime()) / 1000;
      // TODO: add mixpanel data send
      console.log(`passed: ${timepassed} fps: ${numFrames.current / timepassed}`);
    }
    const renderEnd = new Date();

    animationId.current = requestAnimationFrame(renderFrame)
  };

  const calculateVideoSegments = (layers: any[]): Segment[] => {
    const cuts = [];
    let i = 0;
    for (let layer of layers) {
      cuts.push({type: 'start', value: layer.start, layer: layer, index: i});
      cuts.push({type: 'end', value: layer.end, layer: layer, index: i});
      i++;
    }
    const sortedCuts = cuts.sort((a, b) => a.value - b.value);

    const layerOpenMap = layers.map((l, i) => false);
    const segments: Segment[] = [];
    let lastCut: any = null;
    for (const cut of sortedCuts) {
      layerOpenMap[cut.index] = cut.type === 'start';
      if (lastCut && lastCut.type === cut.type && lastCut.value === cut.value) {
        if (cut.type === 'start') {
          // it's the same cut - need add to last segment instead of creating a new segment
          const segmentLayers = [...segments[segments.length-1].layers,  layers[cut.index]];
          const prioritizedSegmentLayers = segmentLayers.sort((a:any, b: any) => b.rowNumber - a.rowNumber);
          segments[segments.length-1].layers = prioritizedSegmentLayers;
        }// else {
          // it's a closing of a cut after the same cut - dont need to do anything just move on
        //}
        continue;
      }
      // add all the open layers to the new segment, but make sure that they are not actually ending at the same time of last segment end...
      const segmentLayers = layers.filter((layer: any, index: number) => layerOpenMap[index] === true && layer.end != cut.value)
      const prioritizedSegmentLayers = segmentLayers.sort((a, b) => b.rowNumber - a.rowNumber);
      if (segments.length) {
        // close the last segment - set the end of it to our new segment start
        segments[segments.length-1].end = cut.value;
      }
      segments.push({ layers: prioritizedSegmentLayers, start: cut.value, end: -1});

      lastCut = cut;
    }
    // remove the last segment - it is the last "end" cut which has no length
    return segments.slice(0, -1);
  }

  const seekAllVideos = async (layers: any[], seconds: number) => {
    //const activeVideo = getActiveVideo(currentTime);
    for (const vid of videos) {
      //if (!vid.dirty || vid == activeVideo) {
      //  // seek only movies that have been changed
      //  // do not seek the currently selected video (user can continue from the same spot)
      //  continue;
      //}
      if (!vid.videoRef) {
        continue;
      }

      // get the video current time
      // seek only if different
      vid.videoRef.currentTime = vid.playOffset;
      vid.videoRef.onseeked = () => {
        vid.videoRef.onseeked = null;
        renderVideoFrameGL(vid.videoRef, vid.x, vid.y, vid.scaledWidth as number, vid.scaledHeight as number, renderCanvas.current, null);
      }
    }
  }

  const getVideoScaleFactor = (video: Video, widthBase: number): number => {
    if (video.scaledWidth === null) {
      return 1;
    }
    // is video width going out of the screen?
    if (video.x + video.scaledWidth  > widthBase) {
      const overflow = (video.x + video.scaledWidth) - widthBase;

      // what percenage should the new video be from the original
      const scaleFactor = (video.scaledWidth - overflow) / video.scaledWidth;
      return scaleFactor;
    }

    return 1;
  }

  const scaleVideo = async (video: Video, oldHeightBase: number, oldWidthBase: number, newWidthBase: number, scale: boolean =true) => {
    if (video.scaledWidth === null || video.scaledHeight === null) {
      console.error("scaleVideo received video with null scaled width or height");
      return video;
    }
    let scaledWidth = oldWidthBase;
    let scaledHeight = oldHeightBase;
    // no need to change anything if the screen is smaller - just keep everything the same size
    if (Math.ceil(newWidthBase) > Math.ceil(oldWidthBase)) {
      return video;
    }
    // if the video is smaller than the new screen width - don't change anything
    if (Math.ceil(video.scaledWidth) <= Math.ceil(newWidthBase)) {
      return video;
    }

    if (scale) {
      scaledWidth = Math.max(Math.ceil(Math.min(video.scaledWidth, newWidthBase)), Math.ceil(newWidthBase)); // width cant be larger than the canvas area
      video.left = Math.ceil(video.x * newWidthBase / oldWidthBase);
      video.x = Math.ceil(video.x * newWidthBase / oldWidthBase);
      scaledWidth = Math.ceil(scaledWidth * (newWidthBase / oldWidthBase));
      scaledHeight = Math.ceil(Math.min(scaledWidth / video.aspectRatio, oldHeightBase));
    } else {
      scaledWidth = Math.ceil(oldWidthBase);
      scaledHeight = Math.ceil(oldHeightBase);
    }
    if (!video.dirty && video.scaledWidth === scaledWidth && video.scaledHeight == scaledHeight) {
      // video already at the right scale, scaling not needed
      return video;
    }
    const newSize = scaledWidth * scaledHeight * 4;
    video.storageBuffer = new Uint8Array(newSize);
    video.scaledHeight = scaledHeight;
    video.scaledWidth = scaledWidth;
    video.baseHeight = scaledHeight;
    video.baseWidth = scaledWidth;

    const effectString = video.getEffectString();
    await Engine.set_effect(video.videoFdRef as number, video.scaledWidth as number, video.scaledHeight as number, effectString).then((res: number) => {
      if (res == -1) {
        console.error("failed to set effect");
        return;
      }
    });

    return video;
  }

  const scaleVid = async (video: Video, scaleFactor: number) => {
    if (video.scaledWidth === null || video.scaledHeight === null) {
      console.error('scale vid received bad video');
      return video;
    }
    const scaledWidth = Math.ceil(video.scaledWidth * scaleFactor);
    const scaledHeight = Math.ceil(video.scaledHeight * scaleFactor);

    const newSize = scaledWidth * scaledHeight * 4;
    video.storageBuffer = new Uint8Array(newSize);
    video.scaledHeight = scaledHeight;
    video.scaledWidth = scaledWidth;
    video.baseHeight = scaledHeight;
    video.baseWidth = scaledWidth;

    if (video.videoFdRef !== null && video.videoFdRef !== undefined) {
      const effectString = video.getEffectString();
      await Engine.set_effect(video.videoFdRef as number, video.scaledWidth, video.scaledHeight, effectString).then((res: number) => {
        if (res == -1) {
          console.error("failed to set effect");
          return;
        }
      });
    }

    return video;
  }

  const scaleNewVideo = async (video: Video, heightBase: number, widthBase: number) => {
    let scaledWidth = Math.ceil(heightBase * video.aspectRatio);
    let scaledHeight;
    if (scaledWidth > widthBase) {
      scaledWidth = Math.ceil(widthBase);
      scaledHeight = Math.ceil(scaledWidth / video.aspectRatio);
    } else {
      scaledHeight = Math.ceil(heightBase);
    }
    if (!video.dirty && video.scaledWidth === scaledWidth && video.scaledHeight == scaledHeight) {
      // video already at the right scale, scaling not needed
      return video;
    }
    const newSize = scaledWidth * scaledHeight * 4;
    video.storageBuffer = new Uint8Array(newSize);
    video.scaledHeight = scaledHeight;
    video.scaledWidth = scaledWidth;
    video.baseHeight = scaledHeight;
    video.baseWidth = scaledWidth;

    return video;
  }

  const scaleAllVideos = async (videos: Video[], heightBase: number, oldWidthBase: number, newWidthBase: number): Promise<any[]> => {
    let newVideos = await Promise.all(videos.map((vid: Video) => {
      if (vid.scaledWidth === null && vid.hasVideo()) {
        return scaleNewVideo(vid, heightBase, newWidthBase)
      }
      return vid;
    }));

    const scaleFactor = Math.min(...newVideos.map((vid: Media) => {
      if (vid.hasVideo() && !(vid instanceof TextMedia)) {
        return getVideoScaleFactor(vid as Video, newWidthBase);
      }
      // if this is not a scallable type - just return factor of 1 (i.e has no effect)
      return 1;
    }));

    if (scaleFactor) {
      return Promise.all(newVideos.map((video: Video) => {
      if (video.hasVideo()) {
        return scaleVid(video, scaleFactor);
      }
      return video;
      }));
    }

    return Promise.all(newVideos.map((video: Video) => {
      return scaleVideo(video, heightBase, oldWidthBase, newWidthBase);
    }));
  }

  const handleLayersChanged = async (layers: any[], totalDuration: number) => {
    const segments = calculateVideoSegments(layers);
    let existingVideos = layers.map((layer: any) => layer.video);

    existingVideos = await scaleAllVideos(existingVideos, canvasHeight, canvasWidth, canvasWidth);

    setVideos(existingVideos);

    videoSegments.current = segments;
    await renderOneFrame(currentTime, true, true);

    // set the playing total duration to the maximum video end
    const maxVideoEnd = Math.max(...layers.map((layer) => layer.end));
    setTotalDuration(maxVideoEnd);

    // set the total duration of the trakcs on screen (part of it not played as no media element there)
    setTotalTracksDuration(totalDuration);
  }

  const handleLayerSelected = (layer: Media | null) => {
    setSelectedVideoLayer(layer);
  }

  const throttledSliderChange = useCallback(
      throttle((sliderTime: any) => {
        setRenderPending(sliderTime);
      }, sliderThrottleTime),
      []
  );

  const debouncedSliderChange = useCallback(
      debounce((sliderTime: any) => {
        setRenderPending(sliderTime);
      }, sliderThrottleTime),
      []
  );

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const sliderTime = Array.isArray(newValue) ? newValue[0] : newValue;
    playStartBase.current = sliderTime;
    playStartTime.current = new Date().getTime() / 1000;

    setCurrentTime(sliderTime);
    throttledSliderChange(sliderTime);
    debouncedSliderChange(sliderTime);
  };

  const handlePlayPause = async () => {

    mixpanel.track('VideoPlayPause', {
     'action': !isRunning,
    });

    shouldRender.current = !shouldRender.current;

    setIsRunning(!isRunning);

    // this is the reverse of what running is, we got event to switch it
    if (!isRunning) {
      playStartTime.current = new Date().getTime() / 1000;
      playStartBase.current = currentTime;
    } else {
      playStartTime.current = null;
      playStartBase.current = null;
    }

    if (shouldRender.current) {
      start.current = new Date();
      numFrames.current = 0;
      renderFrame();
    } else {
      if (animationId.current) {
        cancelAnimationFrame(animationId.current);
      }
      for (const video of videos) {
        video.isPlaying = false;
        if ((video.videoRef && video instanceof Video) ||
            (video.videoRef && video instanceof Audio)) {
          video.videoRef.pause();
        }
      }
    }
  };

  const handleSkipFrame = (direction: 'forward' | 'backward') => {
    const activeVideo = getActiveVideo(videoSegments.current, currentTime);
    if (activeVideo && activeVideo.videoRef) {
      const frameTime = 1 / 30; // Assuming 30 FPS
      let newTime;
      if (direction === 'forward') {
        newTime = Math.min(currentTime + frameTime, totalDuration);
      } else {
        newTime = Math.max(currentTime - frameTime, 0);
      }
      setCurrentTime(newTime);
      renderOneFrame(newTime, true, true);
    }
  };

  function splitTextIntoLines(
    ctx: CanvasRenderingContext2D,
    text: string,
    maxWidth: number
  ): string[] {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    words.forEach((word) => {
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && currentLine) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }

    // Limit to 2 lines is standard convention - but we use 3
    return lines.slice(0, 3);
  }

  const createTextImage = (
    text: string,
    font: string,
    color: string,
    x: number,
    y: number,
    maxWidth: number,
    maxHeight: number,
    lineHeight: number,
    textAlign: string = 'center',
    textBaseline: string = 'top'
  ) => {
    const ctx = draftCanvas.current.getContext('2d', { alpha: true });
    if (!ctx) {
      console.error('Failed to get 2D context from draftCanvas.');
      return;
    }

    // Clear the entire canvas
    ctx.clearRect(0, 0, draftCanvas.current.width, draftCanvas.current.height);

    // Set text properties
    ctx.font = font; // e.g., '24px Arial'
    ctx.fillStyle = color; // e.g., 'white'
    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;

    // Enable text shadow for readability (similar to FFmpeg's BackColour)
    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.shadowBlur = 4;

    // Split the text into lines based on maxWidth
    const lines = splitTextIntoLines(ctx, text, draftCanvas.current.width - 20);

    // Reset fill style to text color
    ctx.fillStyle = color;

    // Draw each line of text
    lines.reverse().forEach((line, index) => {
      ctx.fillText(line, draftCanvas.current.width / 2, draftCanvas.current.height - lineHeight - index * lineHeight);
    });

    // Reset shadow settings
    ctx.shadowColor = 'transparent';

    // Render the draftCanvas onto the WebGL canvas
    renderVideoFrameGL(
      draftCanvas.current,
      x,
      y,
      maxWidth,
      maxHeight,
      renderCanvas.current,
      null,
      false
    );
  };

  const onEffectSelected = async (effectString: string) => {
    mixpanel.track('Filter', {
     'name': effectString,
    });

    if (selectedVideoLayer && (selectedVideoLayer instanceof Video || selectedVideoLayer instanceof Audio)) {
      const videoEffectCommand = new AddVideoEffectCommand(selectedVideoLayer, effectString, () => {
        if (playStartBase.current !== null) {
          renderOneFrame(playStartBase.current, false, true);
        }
      });
      CommandHistory.push(videoEffectCommand);

      await selectedVideoLayer.setEffect(effectString);
      renderOneFrame(currentTime, false, true);
    }
  }

  const onChangeBackground = (color: [number, number, number, number]) => {
    debounceBackgroundChangeHistoryAction(canvasBackground, color, setCanvasBackground)

    setCanvasBackground(color)
    renderOneFrame(currentTime, false, true);
  }

  const handleAspectRatioSelected = async (newRatio: number) => {
    const newCanvasWidth = initialWidth * newRatio;

    const originalVideos = videos.map((vid: Media) => vid.deepCopy());

    const newVideos = await scaleAllVideos(videos, canvasHeight, canvasWidth, newCanvasWidth);

    const addVideosCommand = new ChangeApsectRatioCommand(originalVideos as Video[], newVideos, 0, canvasAspectRatio, newRatio, setCanvasAspectRatio, (newVideos: Video[]) => {
      scaleAllVideos(newVideos, canvasHeight, canvasWidth, initialWidth).then((vids: Media[]) => {
        setVideos(vids);
      })
    })

    CommandHistory.push(addVideosCommand);

    setVideos(newVideos);
    setCanvasAspectRatio(newRatio)
  }

  const handleVideoRotate = async (degrees: number, index: number) => {
    const newVideos = [...videos];
    const curVideo = newVideos[index];
    await curVideo.setRotation(degrees);
    newVideos[index] = curVideo;
    setVideos(newVideos);
  };

  const handleVideoDragStop = (xBefore: number, xAfter: number, yBefore: number, yAfter:number, index: number) => {
    const newVideos = [...videos];
    const oldVideos = [...videos];
    const changedVid = oldVideos[index].deepCopy();
    const originalVid = changedVid.deepCopy();
    originalVid.x = xBefore;
    originalVid.y = yBefore;
    oldVideos[index] = originalVid;
    // needs to invalidate the layers!
    const unSelectVideo = () => setSelectedVideoLayer(null);
    const vidAnimationDragCommand = new VideoAnimationChangeDimensionsCommand([...oldVideos], [...newVideos], originalVid, videoSegments.current, setVideos, (segments: Segment[]) => videoSegments.current = segments, unSelectVideo);
    CommandHistory.push(vidAnimationDragCommand);

    // update the videos
    const updateVideos = [...videos];
    oldVideos[index].x = xAfter;
    oldVideos[index].y = xAfter;
    setVideos(updateVideos)
  };

  // Not sure, but it seems that changes here are persistent - somehow changing newVideos remains?
  // That's fine for now but I don't know why that would happen
  const handleVideoDrag = (x: number, y: number, index: number) => {
    const newVideos = [...videos];
    newVideos[index].x = x;
    newVideos[index].y = y;
    const curSegment = getActiveSegment(videoSegments.current, currentTime);
    const segmentVideos = curSegment.layers.map((layer: any) => layer.video);
    if (segmentVideos.length) {
      renderVideosWithFilter(segmentVideos);
    }
  };

  const handleVideoResizeStop = async (
    xBefore: number,
    yBefore: number,
    widthBefore: number,
    heightBefore: number,
    xAfter: number,
    yAfter: number,
    widthAfter: number,
    heightAfter: number,
    direction: DirectionType,
      index: number) => {

    handleMediaResize(xAfter, yAfter, widthAfter, heightAfter, direction, index);

    const newVideos = [...videos];
    const oldVideos = [...videos];
    const changedVid = oldVideos[index].deepCopy();
    const originalVid = changedVid.deepCopy();
    originalVid.scaledWidth = widthBefore;
    originalVid.scaledHeight = heightBefore;
    originalVid.baseWidth = widthBefore;
    originalVid.baseHeight = heightBefore;
    originalVid.x = xBefore;
    originalVid.y = yBefore;
    oldVideos[index] = originalVid;

    changedVid.scaledWidth = widthAfter;
    changedVid.scaledHeight = heightAfter;
    changedVid.baseWidth = widthAfter;
    changedVid.baseHeight = heightAfter;
    changedVid.x = xAfter;
    changedVid.y = yAfter;
    newVideos[index] = changedVid;

    const unSelectVideo = () => setSelectedVideoLayer(null);
    const vidAnimationDragCommand = new VideoAnimationChangeDimensionsCommand([...oldVideos], [...newVideos], originalVid, videoSegments.current, setVideos, (segments: Segment[]) => videoSegments.current = segments, unSelectVideo);
    CommandHistory.push(vidAnimationDragCommand);
  };

  const handleMediaResize = async (x: number, y: number, width: number, height: number, direction: DirectionType, index: number) => {
    const newVideos = [...videos];
    const newVideo = newVideos[index];

    width = Math.ceil(width);
    height = Math.ceil(height);

    switch (direction) {
      case 'top':
        if ((newVideo.baseHeight < height)) {
          return;
        }
        newVideo.crop.top = (newVideo.baseHeight - height) / newVideo.baseHeight - newVideo.crop.bottom;
        newVideo.crop.top = Math.round((Math.min(Math.max(0, newVideo.crop.top), 1) * 100)) / 100;
        break;
      case 'bottom':
        if ((newVideo.baseHeight < height)) {
          return;
        }
        newVideo.crop.bottom = (newVideo.baseHeight - height) / newVideo.baseHeight - newVideo.crop.top;
        newVideo.crop.bottom = Math.round((Math.min(Math.max(0, newVideo.crop.bottom), 1) * 100)) / 100;
        break;
      case 'left':
        if ((newVideo.baseWidth < width)) {
          return;
        }
        newVideo.crop.left = (newVideo.baseWidth - width) / newVideo.baseWidth - newVideo.crop.right;
        newVideo.crop.left = Math.round((Math.min(Math.max(0, newVideo.crop.left), 1) * 100)) / 100;
        break;
      case 'right':
        if ((newVideo.baseWidth < width)) {
          return;
        }
        newVideo.crop.right = (newVideo.baseWidth - width) / newVideo.baseWidth - newVideo.crop.left;
        newVideo.crop.right = Math.round((Math.min(Math.max(0, newVideo.crop.right), 1) * 100)) / 100;
        break;
    }

    // basically scale this video to new size via engine
    newVideo.x = x;
    newVideo.y = y;
    newVideo.scaledWidth = width;
    newVideo.scaledHeight = height;
    if (!direction) {
      // rescale the baseHeight according to the new height which takes into account that it was cropped
      newVideo.baseHeight = height / (1 - newVideo.crop.top - newVideo.crop.bottom);
      // rescale the baseWidth according to the new width which takes into account that it was cropped
      newVideo.baseWidth = width / (1 - newVideo.crop.left - newVideo.crop.right);
    }
    newVideo.aspectRatio = width / height;
    newVideo.storageBuffer = new Uint8Array(newVideo.scaledWidth * newVideo.scaledHeight * 4);
    newVideos[index] = newVideo;

    if (newVideo.videoFdRef !== null && newVideo.videoFdRef !== undefined) {
      const effectString = newVideo.getEffectString();
      const res = await Engine.set_effect(newVideo.videoFdRef as number, newVideo.scaledWidth, newVideo.scaledHeight, effectString);
      if (res == -1) {
        console.error("failed to set effect");
        return;
      }
    }

    setVideos(newVideos);
  };

  const handleVideoAdded = async (video: Video, isFirstMedia: boolean) => {
    if (!video.videoRef) {
      console.error('handleVideoAdded recieved video without videoRef');
    }

    video.videoRef.currentTime = 0;
    video.videoRef.onseeked = () => {
      video.videoRef.onseeked = null;
      if (video.hasVideo()) {
        if (isFirstMedia) {
          const newWidth = Math.floor(canvasHeight * video.aspectRatio);

          scaleAllVideos([video], canvasHeight, canvasWidth, newWidth).then((newVideos: Video[]) => {

            const newVideo = newVideos[0];
            if (canvasAspectRatio != video.aspectRatio || newWidth !== canvasWidth) {
              setCanvasAspectRatio(video.aspectRatio);
              setCanvasWidth(newWidth);
              setSelectedVideoLayer(video);
            } else {
              renderVideoFrameGL(newVideo.videoRef, newVideo.x, newVideo.y, newVideo.scaledWidth as number, newVideo.scaledHeight as number, renderCanvas.current, null, false);
              if (newVideo.subtitlesActive && newVideo.videoRef.textTracks.length) {
                renderSubtitles(newVideo);
              }
              setSelectedVideoLayer(video);
            }
          })
        }
      }
    };
  }

  const handleDownloadVideo = async () => {
    if (videos.length === 0) {
      // no videos - do not attempt to download
      return;
    }

    mixpanel.track('Download Video Clicked', {
     'numberOfVideos': videos.length
    });

    setIsDownloading(true);
    // set the video resolution
    const videoWidth = videoDownloadWidth;
    let videoHeight = Math.floor(videoWidth / canvasAspectRatio);
    videoHeight += videoHeight % 2; // make sure it's divisable by 2 - needed for scaling on backend

    // serialize all segments
    const serializedContext = serializeSegments(videoSegments.current, canvasBackground, Math.floor(canvasWidth), Math.floor(canvasHeight), videoWidth, videoHeight);
    downloadVideo(serializedContext).then(({videoName}) => {
      mixpanel.track('Download Video Server Response', {
        'name': videoName
      });

      // poll every second till video is downloaded or error occurs
      pollRenderedVideoAndDownload(videoName, 1000, () => {
        mixpanel.track('Download Video Finished', {
          'name': videoName
        });
        setIsDownloading(false);
      });
    }).catch((err: any) => {
      setIsDownloading(false);
      console.error(err);
    });
  }

  const onToggleSubtitles = () => {
    renderOneFrame(currentTime, false, true);
  }

  return (
    <div style={{ maxHeight: maxPageHeight, height: maxPageHeight, flexDirection: 'column', display: 'flex', flexGrow: 1, maxWidth: '100%' }}>
      <div className="pageContainer">
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'start', maxWidth: '100%', overflow: 'hidden' }}>
          <Box sx={{ height: '100%', display: 'flex', flexGrow: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'start', maxWidth: '100%' }}>
            {!isMobile && (
            <EffectSelector
              // TODO: world ugliest (and craziest) way to get the start and end of the media...
              //       keep it in some variable in the media... or at the very least implement a function to do it
              videoStart={(selectedVideoLayer !== null) ? ((selectedVideoLayer.left) / numColumns) * totalTracksDuration : null}
              videoEnd={(selectedVideoLayer !== null) ? ((selectedVideoLayer.left + (selectedVideoLayer.duration / totalTracksDuration) * numColumns - selectedVideoLayer.rightShrink - selectedVideoLayer.leftShrink) / numColumns * totalTracksDuration) : null}
              selectedVideo={selectedVideoLayer as Video}
              canvasBackgroundColor={canvasBackground}
              onSelect={onEffectSelected}
              onTextCreated={videoLayersRef.current ? videoLayersRef.current.onTextCreated : () => {}}
              onTextStyleChanged={videoLayersRef.current ? videoLayersRef.current.onTextStyleChanged : () => {}}
              onSubtitlesGenerate={videoLayersRef.current ? videoLayersRef.current.handleAddSubtitles : () => {}}
              onSubtitlesUpload={videoLayersRef.current ? videoLayersRef.current.onSubtitlesUpload : () => {}}
              onMediaChanged={videoLayersRef.current ? videoLayersRef.current.onMediaChanged : () => {}}
              onChangeBackground={onChangeBackground}
              onAddMedia={videoLayersRef.current ? videoLayersRef.current.onAddMedia : () => {}}
              isMobileLayout={isMobile} />) }
            <Box
                sx={{ display: 'flex', flexGrow: 1, height: '100%', width: '100%', flexDirection: isMobile ? 'column' : 'column', alignItems: 'left', justifyContent: 'center', maxWidth: '100%', overflow: 'hidden' }}
            >
              <EditorTopBar isMobile={isMobile} isDownloading={isDownloading} canvasAspectRatio={canvasAspectRatio} handleDownloadVideo={handleDownloadVideo} handleAspectRatioSelected={handleAspectRatioSelected}></EditorTopBar>
              <Box
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  setSelectedVideoLayer(null);
                }}
                sx={{ display: 'flex', flexGrow: 1, width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', marginTop: '10px', maxWidth: '100%', overflow: 'hidden' }}
              >
                <ResizableBox
                  resizeHandles={[]}
                  width={canvasWidth}
                  height={canvasHeight}
                  minConstraints={[initialWidth*0.5, initialHeight*0.5]}
                  maxConstraints={[initialWidth*1.5, initialHeight*1.5]}
                  lockAspectRatio={true}
                  onResize={(e, data) => {
                    const height = Math.floor(data.size.height);
                    const width = Math.floor(data.size.width);
                    setCanvasHeight(height);
                    setCanvasWidth(width);
                    setRotatableBoxMultiplier({ height: height / initialHeight, width: width / initialWidth });
                  }}
                >
                  <Box style={{height: canvasHeight, width: canvasWidth}}>
                    {videos.map ((video: any, index: number) => (
                      (!(video instanceof Audio) && (
                      <RotatableBox
                        key={video.id}
                        ref={moveableBoxRef}
                        isActive={video.id === (selectedVideoLayer ? selectedVideoLayer.id : null)}
                        width={video.scaledWidth * rotatableBoxMultiplier.width}
                        maxWidth={canvasWidth}
                        maxHorizontalSize={video.baseWidth}
                        height={video.scaledHeight * rotatableBoxMultiplier.height}
                        maxHeight={canvasHeight}
                        cropDimensions={video.crop}
                        maxVerticalSize={video.baseHeight}
                        initialX={video.x * rotatableBoxMultiplier.width}
                        initialY={video.y * rotatableBoxMultiplier.height}
                        zIndex=
                        {
                          (() => {
                            // TODO: this is called every render - should profile to see if slowing us down
                            const curVideo = videos[index];
                            const activeSegment = getActiveSegment(videoSegments.current, currentTime);
                            if (!activeSegment) {
                              return (selectedVideoLayer ? (selectedVideoLayer.id === video.id ? 0 : -1) : -1);
                            }
                            const activeVideos = activeSegment.layers.map((layer: any) => layer.video);
                            if (activeVideos.indexOf(curVideo) === -1) {
                              return (selectedVideoLayer ? (selectedVideoLayer.id === video.id ? 0 : -1) : -1);
                            }
                            // it can go below because high row
                            const maxRow = Math.max(...videos.map(v => v.row));
                            return maxRow - video.row;
                          })()
                        }
                        resizable={video.resizable}
                        cropable={video.cropable}
                        lockAspectRatio={!(video instanceof TextMedia)}
                        onSelect={((event: React.MouseEvent<HTMLDivElement>) => {
                          // stop the propogation so we can identify if someone clicked on the background elements to unselect
                          event.stopPropagation();
                          setSelectedVideoLayer(videos[index]);
                        })}
                        onDragStop={(xBefore, xAfter, yBefore, yAfter) => {
                          handleVideoDragStop(xBefore, xAfter, yBefore, yAfter, index);
                        }}
                        onDrag={(x, y) => {
                          handleVideoDrag(x, y, index);
                        }}
                        onResizeStop={(xBefore, yBefore, widthBefore, heightBefore, xAfter, yAfter, widthAfter, heightAfter, direction) => {
                          handleVideoResizeStop(xBefore, yBefore, widthBefore, heightBefore, xAfter, yAfter, widthAfter, heightAfter, direction, index);
                        }}
                        onResize={(x, y, width, height, direction) => {
                          //handleVideoResize(x, y, width, height, direction, index);
                        }}
                        onRotate={(degrees) => {
                          handleVideoRotate(degrees, index);
                        }}
                        />)))
                    )}
                    <canvas
                      ref={renderCanvas} style={{height: canvasHeight, width: canvasWidth, display: 'block'}}
                    />
                    <canvas
                      ref={draftCanvas} style={{height: canvasHeight, width: canvasWidth, display: 'none'}}
                    />

                  </Box>
                </ResizableBox>
              </Box>
              <Box ref={resizableParentRef} sx={{ width: '100%' }}>
                <ResizableBox
                  width={Infinity}
                  height={initialVideoControlsHeight}
                  minConstraints={[0, 220]}
                  maxConstraints={[Infinity, 800]}
                  resizeHandles={['n']}
                  style={{backgroundColor: '#ffffff', borderTop: '1px solid #ddd'}}
                  handle={isMobile ? (
                    <div
                      style={{
                        position: 'absolute',
                        paddingRight: '16px',
                        paddingLeft: '16px',
                        boxSizing: 'border-box',
                        width: '100%',
                        backgroundColor: '#ffffff', // White background to resemble a card
                        height: '20px',
                        top: '-20px',
                        left: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'ns-resize',
                        borderTop: '1px solid #ddd', // Light border for separation
                        borderRadius: '4px 4px 0 0', // Rounded top corners to resemble a card
                      }}
                    >
                      <div
                        style={{
                          width: '35px',
                          height: '4px',
                          borderRadius: '2px',
                          backgroundColor: '#bbb',
                        }}
                      />
                    </div>
                  ) : (
                    // Your desktop handle implementation
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        backgroundColor: 'transparent',
                        height: '10px',
                        top: '0px',
                        left: '0px',
                        cursor: 'ns-resize',
                      }}
                    />
                  )}
                >
                  <VideoLayers
                    ref={videoLayersRef}
                    currentTime={currentTime}
                    isRunning={isRunning}
                    onToggleSubtitles={onToggleSubtitles}
                    onEffectSelected={onEffectSelected}
                    onVideoAdded={handleVideoAdded}
                    onLayersChanged={handleLayersChanged}
                    onSliderChange={handleSliderChange}
                    onLayerSelected={handleLayerSelected}
                    handlePlayPause={handlePlayPause}
                    handleSkipFrame={handleSkipFrame}
                    onChangeBackground={onChangeBackground}
                    setVideos={setVideos}
                    videos={videos}
                    selectedVideo={selectedVideoLayer}
                    canvasBackgroundColor={canvasBackground}
                    isMobile={isMobile}
                    numColumns={numColumns}
                    />
                </ResizableBox>
              </Box>
            </Box>

          </Box>
        </Box>
      </div>
    </div>
  );
};


export default Home;
