import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import Moveable from "react-moveable";

type DirectionType = 'top' | 'bottom' | 'left' | 'right' | null;

interface MoveableComponentProps {
  width: number;
  maxWidth: number;
  maxHorizontalSize: number;
  height: number;
  maxHeight: number;
  maxVerticalSize: number;
  cropDimensions: any;
  initialX: number;
  initialY: number;
  onDrag: (x: number, y: number) => void;
  onDragStop: (xBefore: number, xAfter: number, yBefore: number, yAfter: number) => void;
  onResize: (x: number, y: number, width: number, height: number, direction: DirectionType) => void;
  onResizeStop: (xBefore: number, yBefore: number, widthBefore: number, heightBefore:number, xAfter: number, yAfter: number, widthAfter:number, heightAfter:number, direction: DirectionType ) => void;
  onRotate: (degrees: number) => void;
  onSelect: (event: any) => void;
  resizable: boolean;
  cropable: boolean;
  isActive: boolean;
  zIndex: number;
  lockAspectRatio: boolean;
}


const MoveableBox = forwardRef(({
  onDrag,
  onDragStop,
  onResize,
  onResizeStop,
  onRotate,
  onSelect,
  width,
  maxWidth,
  maxHorizontalSize,
  height,
  maxHeight,
  maxVerticalSize,
  cropDimensions,
  initialX,
  initialY,
  resizable,
  cropable,
  isActive,
  zIndex,
  lockAspectRatio,
}: MoveableComponentProps, ref) => {
    const [startX, setStartX] = useState<number>(0);
    const [startY, setStartY] = useState<number>(0);
    const [startWidth, setStartWidth] = useState<number>(0);
    const [startHeight, setStartHeight] = useState<number>(0);
    const [direction, setDirection] = useState<DirectionType>(null);
    const [currentX, setCurrentX] = useState<number>(initialX);
    const [currentY, setCurrentY] = useState<number>(initialY);
    const [currentHeight, setCurrentHeight] = useState<number>(height);
    const [currentWidth, setCurrentWidth] = useState<number>(width);
    const targetRef = React.useRef<HTMLDivElement>(null);
    const moveableRef = React.useRef<Moveable>(null);

    useEffect(() => {
        setCurrentHeight(height);
        setCurrentWidth(width);
    }, [height, width]);

    useEffect(() => {
        if (moveableRef.current) {
            moveableRef.current.updateRect();
        }
    }, [currentWidth, currentHeight, currentX, currentY]);

    useEffect(() => {
    }, [zIndex, isActive])

    // Expose methods to the parent component using `useImperativeHandle`
    useImperativeHandle(ref, () => ({
        triggerUpdateRect() {
            if (moveableRef.current) {
                moveableRef.current.updateRect();
            }
        }
    }));

    return (
        <div className={`container2 ${isActive ? 'active' : 'inactive'}`}>
            <div
              onClick={onSelect}
              className="target"
              ref={targetRef}
              style={{
                zIndex: zIndex,
                position: 'absolute',
                height: currentHeight,
                width: currentWidth,
                transform: `translate(${initialX}px, ${initialY}px) rotate(0deg)`,
              }}
            ></div>

            <Moveable
                ref={moveableRef}
                target={targetRef}
                origin={false}
                resizable={isActive && resizable}
                draggable={isActive}
                keepRatio={lockAspectRatio && !direction}
                throttleResize={1}
                renderDirections={cropable ? ["nw","n","ne","w","e","sw","s","se"] : ['nw', 'ne', 'sw', 'se']}
                onResize={e => {
                    let direction: DirectionType = null;
                    if (e.direction[0] === 0 && e.direction[1] === -1) {
                        direction = 'top';
                        e.height = Math.min(maxVerticalSize * (1 - cropDimensions.bottom), e.height);
                    } else if (e.direction[0] === 0 && e.direction[1] === 1) {
                        direction = 'bottom';
                        e.height = Math.min(maxVerticalSize * (1 - cropDimensions.top), e.height);
                    } else if (e.direction[0] === -1 && e.direction[1] === 0) {
                        direction = 'left';
                        e.width = Math.min(maxHorizontalSize * (1- cropDimensions.right), e.width);
                    } else if (e.direction[0] === 1 && e.direction[1] === 0) {
                        direction = 'right';
                        e.width = Math.min(maxHorizontalSize * (1 - cropDimensions.left), e.width);
                    }
                    e.width = Math.max(3, e.width);
                    e.width = Math.min(maxWidth, e.width);
                    e.height = Math.max(3, e.height);
                    e.height = Math.min(maxHeight, e.height);

                    e.width = Math.floor(e.width);
                    e.height = Math.floor(e.height);

                    e.target.style.width = `${e.width}px`;
                    e.target.style.height = `${e.height}px`;
                    e.target.style.transform = e.drag.transform;

                    const x = e.drag.translate[0];
                    const y = e.drag.translate[1];

                    onResize(
                        x, y,
                        e.width, e.height, direction as DirectionType);
                    setCurrentWidth(e.width);
                    setCurrentHeight(e.height);
                    setCurrentX(x)
                    setCurrentY(y);
                    setDirection(direction);
                }}
                onResizeStart={e => {
                    setStartX(currentX);
                    setStartY(currentY);
                    setStartWidth(currentWidth);
                    setStartHeight(currentHeight);
                }}
                onResizeEnd={e => {
                    onResizeStop(startX, startY, startWidth, startHeight, currentX, currentY, currentWidth, currentHeight, direction);
                }}
                throttleDrag={1}
                edgeDraggable={false}
                onDrag={e => {
                    const x = e.translate[0];
                    const y = e.translate[1];
                    setCurrentX(x);
                    setCurrentY(y);

                    e.target.style.transform = e.transform;
                    onDrag(x, y);
                }}
                onDragStart={e => {
                    setStartX(currentX);
                    setStartY(currentY);
                }}
                onDragEnd={e => {
                    onDragStop(startX, currentX, startY, currentY);
                }}
            />
        </div>
    );
});

export {
    type DirectionType
}

export default MoveableBox;
