import React, { useState, useEffect } from 'react';
import {
  Card,
  CardActionArea,
  Typography,
  Grid,
  Slider,
  ToggleButton,
  Box,
  FormControl,
  TextField,
  Popover,
  Autocomplete,
  Divider,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  VerticalAlignTop,
  VerticalAlignCenter,
  VerticalAlignBottom,
} from '@mui/icons-material';
import { ChromePicker, ColorResult } from 'react-color';
import TextMedia from '../media/Text';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  height: '100%',
  overflowY: 'auto',
}));

interface TextOption {
  text: string;
  font: string;
  style: React.CSSProperties;
}

const textOptions: TextOption[] = [
  { text: 'Elegant', font: 'Serif', style: { fontFamily: 'Serif', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Modern', font: 'Sans-serif', style: { fontFamily: 'Arial', fontSize: '22px', fontWeight: 'bold' } },
  { text: 'Bold', font: 'Impact', style: { fontFamily: 'Impact', fontSize: '26px', fontWeight: 'bold' } },
  { text: 'Script', font: 'Cursive', style: { fontFamily: 'Cursive', fontSize: '28px', fontWeight: 'normal' } },
  { text: 'Classic', font: 'Times New Roman', style: { fontFamily: 'Times New Roman', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Techy', font: 'Courier New', style: { fontFamily: 'Courier New', fontSize: '22px', fontWeight: 'normal' } },
  { text: 'Fun', font: 'Comic Sans MS', style: { fontFamily: 'Comic Sans MS', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Retro', font: 'Trebuchet MS', style: { fontFamily: 'Trebuchet MS', fontSize: '26px', fontWeight: 'bold' } },
  { text: 'Classy', font: 'Georgia', style: { fontFamily: 'Georgia', fontSize: '28px', fontWeight: 'normal' } },
  { text: 'Minimal', font: 'Helvetica', style: { fontFamily: 'Helvetica', fontSize: '24px', fontWeight: 'lighter' } },
  { text: 'Elegant Script', font: 'Great Vibes', style: { fontFamily: 'Great Vibes', fontSize: '30px', fontWeight: 'normal' } },
  { text: 'Fancy', font: 'Dancing Script', style: { fontFamily: 'Dancing Script', fontSize: '28px', fontWeight: 'normal' } },
  { text: 'Creative', font: 'Pacifico', style: { fontFamily: 'Pacifico', fontSize: '30px', fontWeight: 'normal' } },
  { text: 'Stylish', font: 'Lobster', style: { fontFamily: 'Lobster', fontSize: '28px', fontWeight: 'bold' } },
  { text: 'Tech', font: 'Roboto', style: { fontFamily: 'Roboto', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Groovy', font: 'Fredoka One', style: { fontFamily: 'Fredoka One', fontSize: '26px', fontWeight: 'bold' } },
  { text: 'Unique', font: 'Abril Fatface', style: { fontFamily: 'Abril Fatface', fontSize: '30px', fontWeight: 'bold' } },
  { text: 'Handwritten', font: 'Shadows Into Light', style: { fontFamily: 'Shadows Into Light', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Playful', font: 'Chewy', style: { fontFamily: 'Chewy', fontSize: '26px', fontWeight: 'normal' } },
  { text: 'Digital', font: 'Press Start 2P', style: { fontFamily: 'Press Start 2P', fontSize: '20px', fontWeight: 'normal' } },
];

const fontOptions = Array.from(
  new Set([
    'Serif', 'Sans-serif', 'Cursive', 'Arial', 'Verdana', 'Helvetica', 'Tahoma', 'Trebuchet MS', 'Times New Roman',
    'Georgia', 'Garamond', 'Courier New', 'Comic Sans MS', 'Impact', 'Lucida Console',
    'Palatino Linotype', 'Book Antiqua', 'Century Gothic', 'Franklin Gothic Medium',
    'Brush Script MT', 'Segoe UI', 'Microsoft Sans Serif', 'Consolas', 'Futura', 'Optima',
    'Roboto', 'Lato', 'Ubuntu', 'Montserrat', 'Raleway', 'PT Sans', 'Oswald', 'Open Sans',
    'Poppins', 'Noto Sans', 'Playfair Display', 'Merriweather', 'Lora', 'Baskerville',
    'Inconsolata', 'Nunito', 'Quicksand', 'Gill Sans', 'Pacifico', 'Dancing Script',
    'Lobster', 'Caveat', 'Amatic SC', 'Shadows Into Light', 'Great Vibes', 'Indie Flower',
    'Abril Fatface', 'Josefin Sans', 'Righteous', 'Anton', 'Baloo', 'Carter One', 'Fredoka One',
    'Bungee', 'Fira Sans', 'Asap', 'Signika', 'Jost', 'Exo', 'Manrope', 'Alegreya',
    'Rubik', 'Teko', 'Archivo Black', 'Tangerine', 'Rye', 'Special Elite', 'Racing Sans One',
    'Press Start 2P', 'Black Ops One', 'Sigmar One', 'Chewy', 'Bangers', 'Permanent Marker',
    'Rock Salt', 'Sacramento', 'Zeyada', 'Allura', 'Courgette', 'Satisfy', 'Kaushan Script',
    'Cinzel', 'Crimson Text', 'Lobster Two', 'Bitter', 'PT Serif', 'Karla', 'Mukta',
    'Rokkitt', 'Fjalla One', 'Nobile', 'Varela Round', 'Muli', 'Source Serif Pro',
  ])
);

type TextAttributes = {
  color: string;
  backgroundColor: string;
  fontSize: number;
  textAlign: CanvasTextAlign;
  verticalAlign: 'top' | 'middle' | 'bottom';
  fontFamily: string;
  fontWeight: 'normal' | 'bold' | 'lighter';
  fontStyle: 'normal' | 'italic';
  textDecoration: 'none' | 'underline';
  customText: string;
};

const initialTextAttributes: TextAttributes = {
  color: '#000000',
  backgroundColor: '#FFFFFF',
  fontSize: 48,
  textAlign: 'center',
  verticalAlign: 'middle',
  fontFamily: 'Roboto',
  fontWeight: 'normal',
  fontStyle: 'normal',
  textDecoration: 'none',
  customText: 'Your Text Here',
};

interface TextMenuProps {
  selectedMedia: TextMedia | null;
  isMobile: boolean;
  onTextCreated: (text: string, style: React.CSSProperties) => void;
  onTextStyleSelect: (text: string, style: React.CSSProperties) => void;
}

const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: theme.shadows[3],
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.02)',
  },
}));

const TextMenu: React.FC<TextMenuProps> = ({ selectedMedia, isMobile, onTextCreated, onTextStyleSelect }) => {
  const theme = useTheme();

  const [textAttributes, setTextAttributes] = useState<TextAttributes>(initialTextAttributes);
  const [anchorElTextColor, setAnchorElTextColor] = useState<null | HTMLElement>(null);
  const [anchorElBgColor, setAnchorElBgColor] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (selectedMedia) {
      const { text, style } = selectedMedia;
      setTextAttributes({
        customText: text || initialTextAttributes.customText,
        fontSize: parseInt(style?.fontSize?.toString() || initialTextAttributes.fontSize.toString()),
        color: style?.color || initialTextAttributes.color,
        backgroundColor: style?.backgroundColor || initialTextAttributes.backgroundColor,
        fontFamily: style?.fontFamily || initialTextAttributes.fontFamily,
        fontWeight: (style?.fontWeight as 'normal' | 'bold' | 'lighter') || initialTextAttributes.fontWeight,
        fontStyle: (style?.fontStyle as 'normal' | 'italic') || initialTextAttributes.fontStyle,
        textDecoration: (style?.textDecoration as 'none' | 'underline') || initialTextAttributes.textDecoration,
        textAlign: (style?.textAlign as CanvasTextAlign) || initialTextAttributes.textAlign,
        verticalAlign: (style?.verticalAlign as 'top' | 'middle' | 'bottom') || initialTextAttributes.verticalAlign,
      });
    } else {
      setTextAttributes(initialTextAttributes);
    }
  }, [selectedMedia]);

  const handleTextAttributeChange = (updatedAttributes: Partial<TextAttributes>) => {
    const newAttributes = { ...textAttributes, ...updatedAttributes };
    setTextAttributes(newAttributes);

    if (selectedMedia) {
      const updatedStyle: React.CSSProperties = {
        fontSize: `${newAttributes.fontSize}px`,
        color: newAttributes.color,
        backgroundColor: newAttributes.backgroundColor,
        textAlign: newAttributes.textAlign,
        verticalAlign: newAttributes.verticalAlign,
        fontFamily: newAttributes.fontFamily,
        fontWeight: newAttributes.fontWeight,
        fontStyle: newAttributes.fontStyle,
        textDecoration: newAttributes.textDecoration,
      };
      onTextStyleSelect(newAttributes.customText || '', updatedStyle);
    }
  };

  const handleSelectStyle = (style: React.CSSProperties) => {
    onTextCreated(textAttributes.customText || '', { ...style });
  };

  // Color Picker Handlers
  const handleTextColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTextColor(event.currentTarget);
  };

  const handleBgColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElBgColor(event.currentTarget);
  };

  const handleTextColorClose = () => {
    setAnchorElTextColor(null);
  };

  const handleBgColorClose = () => {
    setAnchorElBgColor(null);
  };

  const handleTextColorChange = (colorResult: ColorResult) => {
    const { r, g, b, a } = colorResult.rgb;
    handleTextAttributeChange({ color: `rgba(${r},${g},${b},${a})` });
  };

  const handleBgColorChange = (colorResult: ColorResult) => {
    const { r, g, b, a } = colorResult.rgb;
    if (a === 0) {
      handleTextAttributeChange({ backgroundColor: 'transparent' });
    } else {
      handleTextAttributeChange({ backgroundColor: `rgba(${r},${g},${b},${a})` });
    }
  };

  // Render text options
  const renderTextOptions = () => (
    <Box sx={{ direction: 'ltr', paddingBottom: 4 }}>
      <Typography
        variant={'h5'}
        gutterBottom
        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
      >
        Select Text Style
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      <Grid container spacing={2}>
        {textOptions.map((option, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <StyledCard>
              <CardActionArea
                onClick={() => handleSelectStyle(option.style)}
                sx={{
                  height: '100%',
                  width: '100%',
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      ...option.style,
                      textAlign: 'center',
                      color: '#000',
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    title={option.text}
                  >
                    {option.text}
                  </Typography>
                </Box>
              </CardActionArea>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  // Render customization options
  const renderCustomizationOptions = () => (
    <Box sx={{ direction: 'ltr' }}>
      <Typography
        variant={'h5'}
        gutterBottom
        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
      >
        Customize Text
      </Typography>

      <Divider sx={{ marginBottom: 2 }} />

      {/* Input for Custom Text */}
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <TextField
          autoFocus={isMobile ? false : true}
          multiline={true}
          label="Enter Your Text"
          minRows={3}
          maxRows={3}
          value={textAttributes.customText}
          onChange={(e) => handleTextAttributeChange({ customText: e.target.value })}
          variant="outlined"
          InputProps={{
            style: { fontSize: '1rem', height: '100px' },
          }}
        />
      </FormControl>

      {/* Font Family Selector */}
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <Autocomplete
          options={fontOptions}
          value={textAttributes.fontFamily}
          onChange={(e, newValue) => {
            if (newValue) handleTextAttributeChange({ fontFamily: newValue });
          }}
          renderInput={(params) => <TextField {...params} label="Font Family" variant="outlined" />}
          getOptionLabel={(option) => option as string}
          renderOption={(props, option) => (
            <Box component="li" {...props} style={{ fontFamily: option }}>
              {option}
            </Box>
          )}
        />
      </FormControl>

      {/* Font Size Slider */}
      <Typography gutterBottom variant="subtitle1">
        Font Size: <strong>{textAttributes.fontSize}px</strong>
      </Typography>
      <Slider
        value={textAttributes.fontSize}
        min={12}
        max={100}
        onChange={(e, newValue) => handleTextAttributeChange({ fontSize: newValue as number })}
        valueLabelDisplay="auto"
        sx={{ marginBottom: 3 }}
      />

      {/* Font Styles */}
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 3 }}>
        <Tooltip title="Bold">
          <ToggleButton
            value="bold"
            selected={textAttributes.fontWeight === 'bold'}
            onClick={() =>
              handleTextAttributeChange({
                fontWeight: textAttributes.fontWeight === 'bold' ? 'normal' : 'bold',
              })
            }
          >
            <FormatBold />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Italic">
          <ToggleButton
            value="italic"
            selected={textAttributes.fontStyle === 'italic'}
            onClick={() =>
              handleTextAttributeChange({
                fontStyle: textAttributes.fontStyle === 'italic' ? 'normal' : 'italic',
              })
            }
          >
            <FormatItalic />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Underline">
          <ToggleButton
            value="underline"
            selected={textAttributes.textDecoration === 'underline'}
            onClick={() =>
              handleTextAttributeChange({
                textDecoration: textAttributes.textDecoration === 'underline' ? 'none' : 'underline',
              })
            }
          >
            <FormatUnderlined />
          </ToggleButton>
        </Tooltip>
      </Box>

      {/* Text Alignment */}
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 3 }}>
        <Tooltip title="Align Left">
          <ToggleButton
            value="left"
            selected={textAttributes.textAlign === 'left'}
            onClick={() => handleTextAttributeChange({ textAlign: 'left' })}
          >
            <FormatAlignLeft />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Align Center">
          <ToggleButton
            value="center"
            selected={textAttributes.textAlign === 'center'}
            onClick={() => handleTextAttributeChange({ textAlign: 'center' })}
          >
            <FormatAlignCenter />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Align Right">
          <ToggleButton
            value="right"
            selected={textAttributes.textAlign === 'right'}
            onClick={() => handleTextAttributeChange({ textAlign: 'right' })}
          >
            <FormatAlignRight />
          </ToggleButton>
        </Tooltip>
      </Box>

      {/* Text Vertical Alignment */}
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 3 }}>
        <Tooltip title="Vertical Align Top">
          <ToggleButton
            value="top"
            selected={textAttributes.verticalAlign === 'top'}
            onClick={() => handleTextAttributeChange({ verticalAlign: 'top' })}
          >
            <VerticalAlignTop />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Vertical Align Center">
          <ToggleButton
            value="middle"
            selected={textAttributes.verticalAlign === 'middle'}
            onClick={() => handleTextAttributeChange({ verticalAlign: 'middle' })}
          >
            <VerticalAlignCenter />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Vertical Align Bottom">
          <ToggleButton
            value="bottom"
            selected={textAttributes.verticalAlign === 'bottom'}
            onClick={() => handleTextAttributeChange({ verticalAlign: 'bottom' })}
          >
            <VerticalAlignBottom />
          </ToggleButton>
        </Tooltip>
      </Box>

      {/* Color Pickers */}
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 3 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle1" gutterBottom>
            Text Color
          </Typography>
          <Box
            sx={{
              width: 36,
              height: 36,
              backgroundColor: textAttributes.color,
              borderRadius: '50%',
              border: '1px solid #ccc',
              cursor: 'pointer',
              margin: '0 auto',
            }}
            onClick={handleTextColorClick}
            draggable={false}
            onDragStart={(e) => e.preventDefault()} // Prevent dragging callback
          />
          <Popover
            open={Boolean(anchorElTextColor)}
            anchorEl={anchorElTextColor}
            onClose={handleTextColorClose}
            draggable={false}
            onDragStart={(e) => e.preventDefault()} // Prevent dragging callback
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <ChromePicker color={textAttributes.color} onChange={handleTextColorChange} disableAlpha={false} />
          </Popover>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle1" gutterBottom>
            Background Color
          </Typography>
          <Box
            sx={{
              width: 36,
              height: 36,
              backgroundColor: textAttributes.backgroundColor === 'transparent' ? '#f0f0f0' : textAttributes.backgroundColor,
              borderRadius: '50%',
              border: '1px solid #ccc',
              cursor: 'pointer',
              margin: '0 auto',
            }}
            draggable={false}
            onDragStart={(e) => e.preventDefault()} // Prevent dragging callback
            onClick={handleBgColorClick}
          />
          <Popover
            open={Boolean(anchorElBgColor)}
            anchorEl={anchorElBgColor}
            onClose={handleBgColorClose}
            draggable={false}
            onDragStart={(e) => e.preventDefault()} // Prevent dragging callback
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <ChromePicker
              color={textAttributes.backgroundColor === 'transparent' ? 'rgba(255,255,255,0)' : textAttributes.backgroundColor}
              onChange={handleBgColorChange}
              disableAlpha={false}
            />
          </Popover>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Container
      sx={{
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {selectedMedia === null ? renderTextOptions() : renderCustomizationOptions()}
    </Container>
  );
};

export default TextMenu;
