import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Card, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { throttle } from 'lodash';

import { createVideoFd, uploadVideo } from '../utils/utils';
import { getSignedUploadUrl } from '../api/ServerApi';
import { useTheme } from '@mui/material/styles';
import Video from './media/Video';
import Audio from './media/Audio';
import ImageMedia from './media/Image';
import Media from './media/Media';
import TextMedia from './media/Text';
import { Frame } from './Frame';
import getEngine from './EffectsEngine';
import { blobUrlToUint8Array } from '../utils/utils';

const Engine = await getEngine();

interface Frame {
  weight: number;
  url: string;
  index: number;
  time: number;
}

interface VideoFramesComponentProps {
    video: Media;
    numFrames: number;
    leftBackgroundOffset: number;
    rightBackgroundOffset: number;
    size: number;
    hasVideoComponent: boolean;
    hasAudioComponent: boolean;
    preloadedFrames?: any[];
    preloadedAudioFrames?: any[];
    onLoadedVideo: (video: Video, frames: any[], audioFrames: any[]) => void;
    onVideoUploadComplete: (video: Media, newlyUploaded: boolean) => void;
}

const VideoFramesComponent: React.FC<VideoFramesComponentProps> = ({
    video,
    numFrames,
    leftBackgroundOffset,
    rightBackgroundOffset,
    size,
    hasVideoComponent,
    hasAudioComponent,
    preloadedFrames,
    preloadedAudioFrames,
    onLoadedVideo,
    onVideoUploadComplete,
  }) => {
    const theme = useTheme();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [frames, setFrames] = useState<any[]>(preloadedFrames || []);
    const [audioFrames, setAudioFrames] = useState<any[]>(preloadedAudioFrames || []);
    const [aspectRatio, setAspectRatio] = useState(1.0);
    const [hasAudio, setHasAudio] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const videoRef = useRef<any>(null);
    const canvasRef = useRef<any>(null);
    const videoFrameHeight = 40;
    const audioFrameHeight = 20;
    const numFrequencyBins = 128;

    const createVideoFdIfNotExists = async (vid: Video) => {
        if (vid.videoFdRef === null) {
            return await createVideoFd(vid);
        }
        return -1;
    }

    const loadTextComponent = (ctx: any, text: string, style: any, width: number) => {
      createTextTrackOverlay(ctx, text, style, width).then((overlay) => {
        const newFrames: Frame[] = [{weight: 1, url: overlay as string, index: 0, time: 0}];
        setFrames(newFrames);
        setIsLoading(false);
      })
    }

    const throttledTextLoad = useCallback(
        throttle((ctx: any, text: string, style: any, width: number) => {
          loadTextComponent(ctx, text, style, width);
        }, 500),
        []
    );

    useEffect(() => {
      const handleCanPlayThrough = () => {
        videoRef.current?.removeEventListener('canplaythrough', handleCanPlayThrough);
        handleLoadedMetadata(video as Video);
      }

      if (video instanceof Video || video instanceof Audio) {
        videoRef.current.muted = true;
        videoRef.current.play().then(() => {
          videoRef.current.pause();
          videoRef.current.muted = false;
          if (videoRef.current.readyState >= 3) {
              handleLoadedMetadata(video as Video);
          } else {
              videoRef.current.addEventListener('canplaythrough', handleCanPlayThrough);
          }
        });
      } else if (video instanceof TextMedia) {
        const ctx = canvasRef.current.getContext('2d');
        loadTextComponent(ctx, video.text, video.style, size);
      } else if (video instanceof ImageMedia) {
        const newFrames: Frame[] = [];
        for (let i = 0; i < numFrames; i++) {
          newFrames.push({weight: 1, url: video.url as string, index: 0, time: 0});
        }
        setFrames(newFrames);
        setIsLoading(false);
        onLoadedVideo(video as unknown as Video, [], []);
      }
    }, [
      video instanceof TextMedia ? (video as TextMedia).text : null,
      video instanceof TextMedia ? (video as TextMedia).style : null,
      video.id,
      numFrames,
      video.duration
    ]);

    useEffect(() => {
      setFrames(preloadedFrames || frames);
      setAudioFrames(preloadedAudioFrames || audioFrames);
    }, [preloadedFrames, preloadedAudioFrames]);

    useEffect(() => {
      if (video instanceof TextMedia) {
        const ctx = canvasRef.current.getContext('2d');
        throttledTextLoad(ctx, video.text, video.style, size);
      }
    }, [video instanceof TextMedia ? size : null]);

    useEffect(() => {
      setHasAudio(hasAudioComponent);
      setHasVideo(hasVideoComponent);
    }, [hasVideoComponent, hasAudioComponent]);
    
    const handleUploadProgress = (progress: number) => {
      setUploadProgress(progress);
      if (progress === 100) {
        onVideoUploadComplete(video, true);
      }
    }

    const handleLoadedMetadata = (vid: Video) => {
        if (!video.onLoadedCalled) {
         // get signed url
          getSignedUploadUrl(vid.name, vid.type, vid.sha256).then( async (uploadInformation: { generatedName: string, url: string}) => {
            if (uploadInformation.url) {
              const file = await (await fetch(vid.url)).blob();
              // upload the video
              uploadVideo(uploadInformation.url, file, file.type, handleUploadProgress);
            } else {
              onVideoUploadComplete(video, false);
            }
          })
        }

        if (vid.hasVideo()) {
            setHasVideo(true);
            createVideoFdIfNotExists(vid).then((fd: number) => {
                if (fd !== -1) {
                    vid.videoFdRef = fd;
                }

                const progressiveLoad = !preloadedFrames || preloadedFrames?.length === numFrames; // do not progressively load if the number of frames changed (speed change for example)
                captureBothFrames(vid, !!(preloadedFrames || preloadedAudioFrames) && preloadedFrames?.length === numFrames, true, true, progressiveLoad).then(([capturedFrames, capturedAudioFrames]: [any[], any[]]) => {
                    vid.videoRef = videoRef.current;
                    setIsLoading(false);
                    onLoadedVideo(vid, capturedFrames, capturedAudioFrames);
                })
            })
        } else {
            setHasVideo(false);
            const progressiveLoad = !preloadedAudioFrames || preloadedAudioFrames?.length === numFrames; // do not progressively load if the number of frames changed (speed change for example)
            captureBothFrames(vid, !!(preloadedFrames || preloadedAudioFrames) && preloadedAudioFrames?.length === numFrames, false, true, progressiveLoad).then(([capturedFrames, capturedAudioFrames]: [any[], any[]]) => {
                vid.videoRef = videoRef.current;
                setIsLoading(false);
                onLoadedVideo(vid, capturedFrames, capturedAudioFrames);
            })
        }
        const curVideoRef = videoRef.current;
        const ratio = curVideoRef.videoHeight / curVideoRef.videoWidth;
        setAspectRatio(ratio);
    };

    const createTextTrackOverlay = async (
      ctx: CanvasRenderingContext2D,
      text: string,
      style: React.CSSProperties,
      canvasWidth: number
    ): Promise<string> => {
      return new Promise((resolve) => {
        canvasRef.current.width = canvasWidth;
        canvasRef.current.height = videoFrameHeight + audioFrameHeight;
        const width = canvasRef.current!.width;
        const height = canvasRef.current!.height;
    
        // Set the background color if provided in style, otherwise default to a grey background
        ctx.clearRect(0, 0, width, height);
        ctx.fillStyle = style.backgroundColor || '#FFFFFF'; // Use backgroundColor from style or default to light grey
        ctx.fillRect(0, 0, width, height);
    
        // ----------- Top Part (Black Text on White Background) -----------
        // Set the font and text color for the main text
        ctx.font = `${style.fontStyle || 'normal'} ${style.fontWeight || 'normal'} ${style.fontSize || '24px'} ${style.fontFamily || 'Arial'}`;
        ctx.fillStyle = '#000000'; // Black text color for top part
    
        // Truncate text if it's too long for the width
        const truncateText = (ctx: CanvasRenderingContext2D, text: string, maxWidth: number): string => {
          let truncated = text;
          let textWidth = ctx.measureText(truncated).width;
    
          // Keep removing characters and adding '...' until it fits within the maxWidth
          while (textWidth > maxWidth && truncated.length > 0) {
            truncated = truncated.slice(0, -1); // Remove last character
            textWidth = ctx.measureText(truncated + '...').width; // Measure with ellipsis
          }
          return truncated.length < text.length ? truncated + '...' : truncated; // Append ellipsis if truncated
        };
    
        const truncatedText = truncateText(ctx, text, width - 20); // Leave small margin
        const topTextWidth = ctx.measureText(truncatedText).width;
        const topTextX = (width - topTextWidth) / 2; // Center horizontally
        const topTextY = height / 2; // Center vertically in the top part
    
        // Draw the black text on the top part of the canvas
        ctx.fillText(truncatedText, topTextX, topTextY);
    
        // ----------- Bottom Overlay (White Text on Semi-Transparent Background) -----------
        // Draw a semi-transparent rectangle overlay at the bottom
        ctx.fillStyle = theme.palette.primary.main; // Semi-transparent black overlay
        ctx.fillRect(0, height - 20, width, 20); // Draw the overlay box at the bottom
    
        // Set the font for the "Text" label at the bottom
        ctx.font = 'bold 20px Arial'; // Font for the label
        ctx.fillStyle = '#FFFFFF'; // White text color for the bottom part
        const label = 'Text'; // Label for the overlay
        const labelWidth = ctx.measureText(label).width;
        const labelX = (width - labelWidth) / 2; // Center horizontally
        const labelY = height - 3; // Vertically position the text inside the overlay box
    
        // Draw the "Text" label on the overlay
        ctx.fillText(label, labelX, labelY);
    
        // Convert the canvas to a data URL
        const frame = canvasRef.current!.toDataURL('image/png');
        resolve(frame);
      });
    };

    const captureVideoFrame = async (video: any, ctx: any, time: any): Promise<string> => {
        return new Promise((resolve) => {
            video.currentTime = time;
            return video.onseeked = async () => {
                const width = canvasRef.current!.width;
                const height = canvasRef.current!.height;

                ctx.clearRect(0, 0, width, height);
                ctx.fillStyle = 'black';
                ctx.fillRect(0, 0, width, height);

                ctx.drawImage(video, 0, 0, canvasRef.current.width, canvasRef.current.height);
                const frame = await canvasRef.current.toDataURL('image/bmp');
                resolve(frame);
            };
        });
    };

    const captureAudioFrame = (data: Float64Array, maxMagnitude: number, ctx: any) => {
        return new Promise((resolve) => {
            const width = canvasRef.current!.width;
            const height = canvasRef.current!.height;

            // Step 1: Set the entire background to black
            ctx.clearRect(0, 0, width, height);
            ctx.fillStyle = 'black';
            ctx.fillRect(0, 0, width, height);

            // Step 2: Define the bar width and spacing
            const spacing = 2; // Space between bars
            //const barWidth = (width / data.length) - spacing;
            const barWidth = 10;

            // Step 3: Create a strong gradient for the bars
            const gradient = ctx.createLinearGradient(0, 0, 0, height);
            gradient.addColorStop(0, '#00FFFF');  // Cyan at the top
            gradient.addColorStop(0.5, '#0000FF'); // Blue in the middle
            gradient.addColorStop(1, '#FF00FF');  // Magenta at the bottom

            // Step 4: Draw the spectrum bars
            for (let i = 0; i < data.length; i++) {
                const magnitude = data[i]; // Assuming magnitude is scaled appropriately
                const x = i * (barWidth + spacing); // Calculate the x position
                const barHeight = (magnitude / maxMagnitude) * height; // Calculate the height of the bar

                // Set fill style to gradient and draw the bar
                ctx.fillStyle = gradient;
                //ctx.fillStyle = '#0000ff';
                ctx.fillRect(x, height - barHeight, barWidth, barHeight);
            }

            // Step 5: Capture the frame as an image
            const frame = canvasRef.current!.toDataURL('image/bmp');
            resolve(frame);
        });
    }

    const captureBothFrames = async (captureVideo: Video, usePreloadedFrames: boolean, loadVideo: boolean, loadAudio: boolean, progressiveLoad: boolean): Promise<[any[], any[]]> => {
        return await blobUrlToUint8Array(captureVideo.url).then((arr: any) => {
            return Engine.writeFile(captureVideo.storageFilePath, arr).then(async (_: any) => {
                let placeholderFrames: Frame[] = [];
                let placeholderAudioFrames: Frame[] = [];
                const videoElement = videoRef.current;
                if (usePreloadedFrames) {
                  placeholderFrames = preloadedFrames ? [...preloadedFrames] : [];
                  placeholderAudioFrames = preloadedAudioFrames ? [...preloadedAudioFrames] : [];
                } else {
                  // TODO: fix all the casting
                  const freqData = (await Engine.get_audio_frequencies([captureVideo.storageFilePath, (numFrequencyBins as unknown) as string, (numFrames as unknown) as string]) as unknown) as Float64Array;
                  const maxMagnitude = Math.max(...freqData);
                  const freqDataSamples = Array.from({ length: numFrames }, (_, i) => new Float64Array(freqData.buffer, i * numFrequencyBins * Float64Array.BYTES_PER_ELEMENT, numFrequencyBins));

                  const videoHasAudio = maxMagnitude > 0 && hasAudioComponent;
                  setHasAudio(videoHasAudio);

                  const ctx = canvasRef.current.getContext('2d');
                  const interval = (captureVideo.duration * captureVideo.speed) / numFrames;

                  for (let i = 0; i < numFrames; i++) {
                      if (loadVideo) {
                        placeholderFrames.push({weight: 10, url: '', index: i, time: 0});
                      }
                      if (loadAudio) {
                        placeholderAudioFrames.push({weight: 10, url: '', index: i, time: 0});
                      }
                  }

                  if (loadVideo && progressiveLoad) {
                    setFrames(placeholderFrames);
                  }

                  if (loadAudio && progressiveLoad) {
                    setAudioFrames(placeholderAudioFrames);
                  }

                  for (let i = 0; i < numFrames; i++) {
                      const time = i * interval+0.1 + captureVideo.framesOffset;

                      if (loadAudio) {
                        const audioFrame = await captureAudioFrame(freqDataSamples[i], maxMagnitude, ctx);
                        placeholderAudioFrames[i] = {weight: 10, url: audioFrame as string, index: i, time: time};
                      }

                      if (loadVideo) {
                        const videoFrame = await captureVideoFrame(videoElement, ctx, time);
                        placeholderFrames[i] = {weight: 10, url: videoFrame as string, index: i, time: time};
                      }

                      if (loadVideo && progressiveLoad) {
                        setFrames([...placeholderFrames]);
                      }
                      if (loadAudio && progressiveLoad) {
                        setAudioFrames([...placeholderAudioFrames]);
                      }
                  }
                }

                if (loadAudio) {
                  setAudioFrames([...placeholderAudioFrames]);
                }
                if (loadVideo) {
                  setFrames([...placeholderFrames]);
                }
                videoElement.currentTime = captureVideo.framesOffset;
                videoElement.onseeked = () => {
                }
                return [placeholderFrames, placeholderAudioFrames];
            })
        })
    };

    return (
        <Box sx={{ pointerEvents: 'none', width: '100%', display: 'flex', height: videoFrameHeight + audioFrameHeight }}>
          {(isLoading || (uploadProgress !== null && uploadProgress !== 100)) && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '60px', // Ensure height matches your requirement
              backgroundColor: 'rgba(0, 0, 0, 0.4)', // Darken background slightly for visibility
              zIndex: 1,
              color: '#fff',
            }}
          >
            {/* Circular progress compact */}
            <CircularProgress size={24} sx={{ color: '#00ADEF', mr: 1 }} />

            {uploadProgress !== null && (
            <Box>
              {/* Cloud upload icon with smaller size */}
              <CloudUploadIcon sx={{ fontSize: 24, color: '#fff', mr: 1 }} />

              {/* Upload percentage, sized to fit the space */}
              <Box
                sx={{
                  fontWeight: 'bold',
                  fontSize: '0.9rem',
                }}
              >
                {`${uploadProgress}%`}
              </Box>

              {/* Gradient progress bar at the bottom of the 60px height box */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  height: '4px', // Make the progress bar more compact
                  background: 'linear-gradient(90deg, #00ADEF 0%, #00EFA1 100%)',
                  width: `${uploadProgress}%`,
                  borderRadius: '2px',
                }}
              />
            </Box>
            )}
          </Box>
          )}
        <Card
          sx={{
            overflow: 'hidden',
            flexGrow: 1,
            display: 'flex',
            opacity: 0.0,
          }}
        />
        <video ref={videoRef} src={(video instanceof Video || video instanceof Audio) ? video.url : undefined} preload="auto" style={{ display: 'none' }} playsInline/>
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: `${leftBackgroundOffset}px`,
            width: `${-leftBackgroundOffset}px`,
            height: '100%',
            backgroundColor: 'black',
            zIndex: 0,
            opacity: 0.5,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: `${leftBackgroundOffset}px`,
            width: `${size}px`,
            height: '100%',
            zIndex: -1,
          }}
        >
          {hasVideo && (
            <Box
              sx={{
                flexGrow: 2, // Make the video frame take 2/3 of the height
                maxHeight: hasAudio ? videoFrameHeight : '100%', // TODO: why doesnt it work without this
                minHeight: hasAudio ? videoFrameHeight : '0%', // TODO: why doesnt it work without this
                width: '100%',
                display: 'flex',
                overflow: 'hidden',
              }}
            >
              {frames.map((frame: Frame, index) => (
                <Box
                  key={index}
                  sx={{
                    flexGrow: frame.weight,
                    //width: videoFrameHeight / aspectRatio,
                    width: '0px',
                    borderRight: '1px solid white',
                  }}
                >
                  <Frame imageUrl={frame.url} index={frame.index} displayLoadingImage={false} />
                </Box>
              ))}
            </Box>
          )}
          {hasAudio && (
            <Box
              sx={{
                flexGrow: 1, // Make the audio frame take 1/3 of the height
                minHeight: audioFrameHeight, // TODO: why doesnt it work without this
                width: '100%',
                display: 'flex',
                overflow: 'hidden',
              }}
            >
              {audioFrames.map((frame: Frame, index) => (
                <Box
                  key={index}
                  sx={{
                    flexGrow: frame.weight,
                    width: '0px',
                    //width: audioFrameHeight / aspectRatio,
                    borderRight: '1px solid white',
                  }}
                >
                  <Frame imageUrl={frame.url} index={frame.index} displayLoadingImage={false} />
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: `${size + leftBackgroundOffset - rightBackgroundOffset}px`,
            width: `${rightBackgroundOffset}px`,
            height: '100%',
            backgroundColor: 'black',
            zIndex: 0,
            opacity: 0.5,
          }}
        />
      </Box>
    );
};

export { VideoFramesComponent };
