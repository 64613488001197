import _ from 'lodash';

import getEngine from '../EffectsEngine';
import Video from '../media/Video';
import Media from '../media/Media';
import TextMedia from '../media/Text';
import { createVideoFd, createDebouncedActionWithInitialState, createTextImage } from '../../utils/utils';

const Engine = await getEngine();

interface Command {
    execute(): void;
    undo(): void;
}

class VideoAnimationCommandBase implements Command {
    protected originalVideos: Video[];
    protected modifiedVideos: Video[];

    constructor(
      videosBefore: Video[],
      videosAfter: Video[],
      private updateVideos: (newVideos: Video[]) => void
    ) {
      this.originalVideos = videosBefore.map(vid => vid.deepCopy());
      this.modifiedVideos = videosAfter.map(vid => vid.deepCopy());
    }

    execute() {
      this.updateVideos([...this.modifiedVideos]);
    }

    undo() {
      this.updateVideos([...this.originalVideos]);
    }
}

function deepCopySegments(
  segments: any[], 
  videoMap: Map<string, Video>
): any[] {
  return segments.map((seg: any) => {
    const layers = seg.layers.map((layer: any) => {
      const newLayer = { ...layer };
      const deepCopiedVideo = videoMap.get(layer.video.id);
      if (deepCopiedVideo) {
        newLayer.video = deepCopiedVideo;
      } else {
        newLayer.video = layer.video; // Fallback in case the video isn't found in the map
      }
      return newLayer;
    });
    return { ...seg, layers };
  });
}

// TODO: we still have the Moveable box not instantly moving to the right place (only after next click)
class VideoAnimationChangeDimensionsCommand extends VideoAnimationCommandBase {
  private originalSegments: Video[];
  private modifiedSegments: Video[];

  constructor(
    videosBefore: Video[],
    videosAfter: Video[],
    originalVideo: Video,
    segmentsAfter: any[],
    updateVideos: (newVideos: Video[]) => void,
    private updateSegments: (newSegments: any[]) => void,
    private unselectVideo: () => void
  ) {
    super(videosBefore, videosAfter, updateVideos);

    // Create maps for original and modified videos using the videos from the superclass
    const originalVideoMap = new Map<string, Video>();
    this.originalVideos.forEach(video => originalVideoMap.set(video.id, video));

    const modifiedVideoMap = new Map<string, Video>();
    this.modifiedVideos.forEach(video => modifiedVideoMap.set(video.id, video));

    // Use the utility function to deep copy the segments with the correct video references
    this.modifiedSegments = deepCopySegments(segmentsAfter, modifiedVideoMap);
    this.originalSegments = deepCopySegments(segmentsAfter, originalVideoMap);
  }

  execute() {
    super.execute();

    // Use the utility function to deep copy the modified segments before updating
    this.updateSegments(deepCopySegments(this.modifiedSegments, new Map()));
    this.unselectVideo();
  }

  undo() {
    super.undo();

    // Use the utility function to deep copy the original segments before updating
    this.updateSegments(deepCopySegments(this.originalSegments, new Map()));
    this.unselectVideo();
  }
}

class SelectVideoCommand implements Command {
    constructor(
      private videoBefore: any,
      private videoAfter: any,
      private selectVideo: (video: any) => void
    ) {}

    execute() {
      this.selectVideo(this.videoAfter);
    }

    undo() {
      this.selectVideo(this.videoBefore);
    }
}

class AddVideoEffectCommand implements Command {
    constructor(
      private video: any,
      private effect: string,
      private updateVideos: () => void
    ) {}

    execute() {
      this.video.addEffect(this.effect, false);
      this.updateVideos();
    }

    undo() {
      this.video.addEffect(this.effect, false);
      this.updateVideos();
    }
}

class ResizeVideoCommand implements Command {
    originalVideos: any[];
    modifiedVideos: any[];
    constructor(
      private videosBefore: any[],
      private videosAfter: any[],
      private updateVideos: (newVideos: any[]) => void
    ) {
      this.originalVideos = videosBefore.map(vid => vid.deepCopy());
      this.modifiedVideos = videosAfter.map(vid => vid.deepCopy());
    }

    execute() {
      this.updateVideos([...this.modifiedVideos]);
    }

    undo() {
      this.updateVideos([...this.originalVideos]);
    }
}

class DeleteVideoCommand implements Command {
    originalVideos: any[];
    modifiedVideos: any[];
    originalVideo: any;
    durationBefore: number;
    durationAfter: number;
    constructor(
      private videosBefore: any[],
      private videosAfter: any[],
      private deletedVideoIndex: number,
      private totalDurationBefore: number,
      private totalDurationAfter: number,
      private updateVideos: (newVideos: any[], newDuration: number) => void
    ) {
      this.originalVideo = videosBefore[deletedVideoIndex].deepCopy();
      this.originalVideos = videosBefore.map(vid => vid.deepCopy());
      this.modifiedVideos = videosAfter.map(vid => vid.deepCopy());
      this.durationAfter = totalDurationAfter;
      this.durationBefore = totalDurationBefore;
    }

    execute() {
      //if (this.originalVideo.videoFdRef !== null) {
      //  Engine.close_movie([(this.originalVideo.videoFdRef as unknown) as string]).then((res: number) => {
      //    if (res === -1) {
      //      console.error('failed to close movie');
      //    }
      //  })
      //}
      this.updateVideos([...this.modifiedVideos], this.durationAfter);
    }

    undo() {
      // TODO: need to recreate nd delete a blob on file delete? for now we leave the blobs so...
      // this will happen twice
      // TODO: need to make sure that the FD of the segments is the same!
      //createVideoFd(this.originalVideo).then((fd: number) => {
      //  if (fd !== -1) {
      //    this.originalVideo.videoFdRef = fd;
      //  }
      //  this.originalVideo.dirty = true;
      //  this.originalVideos[this.deletedVideoIndex] = this.originalVideo.deepCopy();

      //  this.updateVideos([...this.originalVideos]);
      //})
      // TODO: when we do several undos we can delete movies and because we restore from a snapshot of videos some of them have old fds that may be closed...
      // need to deal with that before we can close / open using c code handles... for now we dont close resources...
      this.updateVideos([...this.originalVideos], this.durationBefore);
    }
}

class AddVideoCommand implements Command {
    originalVideos: any[];
    modifiedVideos: any[];
    originalVideo: any;
    constructor(
      private videosBefore: any[],
      private videosAfter: any[],
      private deletedVideoIndex: number,
      private updateVideos: (newVideos: any[]) => void
    ) {
      this.originalVideo = videosAfter[deletedVideoIndex].deepCopy();
      this.originalVideos = videosBefore.map(vid => vid.deepCopy());
      this.modifiedVideos = videosAfter.map(vid => vid.deepCopy());
    }

    execute() {
      // need to recreate nd delete a blob on file delete? for now we leave the blobs so...
      // this will happen twice
      //createVideoFd(this.originalVideo).then(fd => {
      //  if (fd !== -1) {
      //    this.originalVideo.videoFdRef = fd;
      //  }
      //  this.originalVideo.dirty = true;
      //  this.originalVideos[this.deletedVideoIndex] = this.originalVideo.deepCopy();

      //  this.updateVideos([...this.modifiedVideos]);
      //})
      this.updateVideos([...this.modifiedVideos]);
    }

    undo() {
      // TODO: same issues as in delete
      //if (this.originalVideo.videoFdRef !== null) {
      //  Engine.close_movie([(this.originalVideo.videoFdRef as unknown) as string]).then((res: number) => {
      //    if (res === -1) {
      //      console.error('failed to close movie');
      //    }
      //  })
      //}
      this.updateVideos([...this.originalVideos]);
    }
}

class ChangeApsectRatioCommand extends AddVideoCommand {
    private aspectRatioBefore: number;
    private aspectRatioAfter: number;
    private updateAspectRatio: (newRatio: number) => void;
    constructor(
      videosBefore: any[],
      videosAfter: any[],
      deletedVideoIndex: number,
      aspectRatioBefore: number,
      aspectRatioAfter: number,
      updateAspectRatio: (newRatio: number) => void,
      updateVideos: (newVideos: any[]) => void
    ) {
      super(videosBefore, videosAfter, deletedVideoIndex, updateVideos);
      this.aspectRatioAfter = aspectRatioAfter;
      this.aspectRatioBefore = aspectRatioBefore;
      this.updateAspectRatio = updateAspectRatio;
    }

    execute() {
      // need to recreate nd delete a blob on file delete? for now we leave the blobs so...
      // this will happen twice
      //createVideoFd(this.originalVideo).then(fd => {
      //  if (fd !== -1) {
      //    this.originalVideo.videoFdRef = fd;
      //  }
      //  this.originalVideo.dirty = true;
      //  this.originalVideos[this.deletedVideoIndex] = this.originalVideo.deepCopy();

      //  this.updateVideos([...this.modifiedVideos]);
      //})
      super.execute();
      this.updateAspectRatio(this.aspectRatioAfter);
    }

    undo() {
      // TODO: same issues as in delete
      //if (this.originalVideo.videoFdRef !== null) {
      //  Engine.close_movie([(this.originalVideo.videoFdRef as unknown) as string]).then((res: number) => {
      //    if (res === -1) {
      //      console.error('failed to close movie');
      //    }
      //  })
      //}
      super.undo();
      this.updateAspectRatio(this.aspectRatioBefore);
    }
}

class ChangeBackgroundCommand {
    private previousBackground: [number, number, number, number];
    private newBackground: [number, number, number, number];
    private changeCanvasBackground: (color: [number, number, number, number]) => void;
    constructor(
      previousBackground: [number, number, number, number],
      newBackground: [number, number, number, number],
      changeCanvasBackground: (color: [number, number, number, number]) => void
    ) {
      this.previousBackground = [...previousBackground];
      this.newBackground = [...newBackground];
      this.changeCanvasBackground = changeCanvasBackground;
    }

    execute() {
      this.changeCanvasBackground(this.newBackground);
    }

    undo() {
      this.changeCanvasBackground(this.previousBackground);
    }
}

class CommandHistory {
    private static history: Command[] = [];
    private static undoneCommands: Command[] = [];


    static push(command: Command) {
        this.history.push(command);
        this.undoneCommands = [];
    }

    static executeCommand(command: Command) {
        command.execute();
        this.history.push(command);
        this.undoneCommands = [];
    }

    static undo() {
        const command = this.history.pop();
        if (command) {
            command.undo();
            this.undoneCommands.push(command);
        }
    }

    static redo() {
        const command = this.undoneCommands.pop();
        if (command) {
            command.execute();
            this.history.push(command);
        }
    }
}

const debounceBackgroundChangeHistoryAction = createDebouncedActionWithInitialState(({initialState, latestState}: {initialState: any, latestState: any}, setBackground: (color: [number, number, number, number]) => void) => {
  const addVideosCommand = new ChangeBackgroundCommand(initialState as [number, number, number, number], latestState as [number, number, number, number], setBackground); 
  CommandHistory.push(addVideosCommand)
}, 300);

const debouncedMediaChangeHistoryAction = createDebouncedActionWithInitialState(({initialState, latestState}: {initialState: any, latestState: any}, selectedIndex: number, setVideos: (videos: Media[]) => void) => {
  const addVideosCommand = new AddVideoCommand(initialState as Video[], latestState as Video[], selectedIndex, (changeVideos: Video[]) => {
    setVideos(changeVideos);
  });

  CommandHistory.push(addVideosCommand)
}, 300);

const debouncedTextChangeHistoryAction = createDebouncedActionWithInitialState(({initialState, latestState}: {initialState: any, latestState: any}, selectedIndex: number, setVideos: (videos: Media[]) => void) => {
  const addVideosCommand = new AddVideoCommand(initialState as Video[], latestState as Video[], selectedIndex, (changeVideos: Media[]) => {
    const changedMedia = changeVideos[selectedIndex] as TextMedia;
    createTextImage(changedMedia.videoRef, changedMedia.text, changedMedia.style, changedMedia.scaledWidth as number, changedMedia.scaledHeight as number).then(() => {
      setVideos(changeVideos);
    })
  });

  CommandHistory.push(addVideosCommand)
}, 300);

export default CommandHistory;

export {
  SelectVideoCommand,
  ResizeVideoCommand,
  DeleteVideoCommand,
  AddVideoCommand,
  AddVideoEffectCommand,
  VideoAnimationChangeDimensionsCommand,
  ChangeApsectRatioCommand,
  debounceBackgroundChangeHistoryAction,
  debouncedMediaChangeHistoryAction,
  debouncedTextChangeHistoryAction
}