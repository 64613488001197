import CryptoJS from 'crypto-js';
import mixpanel from 'mixpanel-browser';
import { useRef, useCallback } from 'react';
import { debounce } from 'lodash';

import { getRenderTasks, getSubtitlesTasks, getSignedSubtitlesDownloadUrl } from '../api/ServerApi';
import getEngine from '../components/EffectsEngine';

const taskCompleteStatuses = ['STOPPED', 'SUCCEEDED', 'FAILED', 'DEPROVISIONING', 'STOPPING'];

const Engine = await getEngine();

const blobUrlToUint8Array = async (blobUrl: string) => {
  // Step 1: Fetch the Blob from the URL
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  // Step 2: Convert the Blob to an ArrayBuffer
  const arrayBuffer = await blob.arrayBuffer();

  // Step 3: Convert the ArrayBuffer to a Uint8Array
  const uint8Array = new Uint8Array(arrayBuffer);

  return uint8Array;
}

const createVideoFd = async (video: any) => {
  const url = video.url;
  const filename = url.split('/').slice(-1); // get the guid for the blob
  return blobUrlToUint8Array(url).then((arr) => {
    return Engine.writeFile(`${filename}.mp4`, arr).then((_: any) => {
      return Engine.open_movie([`${filename}.mp4`]).then((fd: number) => {
        if (fd == -1) {
          console.error('engine returned bad fd')
          return -1;
        }
        return fd;
      });
    })
  });
}

const throttle: any = (callback: (...args: any[]) => any, throttleSeconds: number): (...args: any[]) => any => {
  let lastActivationTime: number | null = null;
  let activeTimeout = false;
  const throttledFunc = (...args: any[]) => {
    const timeNow = Date.now();

    const timeDiff = (lastActivationTime !== null) ?
      ((timeNow - lastActivationTime) / 1000) :
      throttleSeconds + 1;

    if (timeDiff > throttleSeconds) {
      lastActivationTime = timeNow;
      callback(...args);
    } else {
      if (!activeTimeout) {
        // add a call to the function after 300 seconds so we always have
        // a final call after the user stops
        activeTimeout = true;
        setTimeout(() => {
          activeTimeout = false;
          callback(...args);
        }, throttleSeconds);
      }
    }
  }
  return throttledFunc;
}

// Web Crypto API-based implementation
const calculateSha256WebCrypto = async (blob: Blob): Promise<string> => {
  const arrayBuffer = await blob.arrayBuffer();

  try {
    // Use the Web Crypto API to calculate the SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);

    // Convert the hashBuffer to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Convert bytes to hex string

    return hashHex;
  } catch (error) {
    throw new Error("Web Crypto API not supported or failed.");
  }
};

// Fallback: CryptoJS-based implementation
const calculateSha256CryptoJS = async (blob: Blob): Promise<string> => {
  const arrayBuffer = await blob.arrayBuffer();

  // Convert the ArrayBuffer to a WordArray (required by CryptoJS)
  const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(arrayBuffer));

  // Hash the WordArray using SHA-256
  const hash = CryptoJS.SHA256(wordArray);

  // Convert the hash to a hex string
  return hash.toString(CryptoJS.enc.Hex);
};

// Unified function with fallback
const calculateSha256 = async (blob: Blob): Promise<string> => {
  if (window.crypto && crypto.subtle) {
    try {
      // Attempt to use the Web Crypto API
      return await calculateSha256WebCrypto(blob);
    } catch (error) {
      console.warn('Web Crypto API failed, falling back to CryptoJS:', error);
    }
  }

  // Fallback to CryptoJS if Web Crypto is not available or fails
  return calculateSha256CryptoJS(blob);
};

const uploadVideo = (url: string, video: Blob, fileType: string, onProgress: (percentage: number) => void): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.withCredentials = true;

    // Set up the progress listener
    xhr.upload.onprogress = (event: ProgressEvent) => {
      if (event.lengthComputable) {
        const percentage = Math.round((event.loaded / event.total) * 100);
        onProgress(percentage);
      }
    };

    // Success and error handlers
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve();  // File uploaded successfully
      } else {
        reject(new Error(`Failed to upload file: ${url}, Status: ${xhr.status}`));
      }
    };

    xhr.onerror = () => reject(new Error('Network error occurred during upload'));

    // Set up and initiate the request
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', fileType);

    // Send the video blob
    xhr.send(video);
  });
};

const pollSubtitlesAndDownload = async (videoName: string, pollInterval: number, callback: (url: string | null) => void) => {
  try {
      const info = await getSubtitlesTasks();
      if (info === null) {
          callback(null);
          // if there is no info, something is wrong - the subtitles were not created
          return;
      }

      const foundVideos = info.filter((vid: any) => vid.fileName === videoName);
      if (foundVideos.length === 0) {
        setTimeout(() => pollSubtitlesAndDownload(videoName, pollInterval, callback), pollInterval);
        return;
      }
      const foundVideo = foundVideos[0];

      if (!taskCompleteStatuses.includes(foundVideo.status)) {
        // movie is still pending
        setTimeout(() => pollSubtitlesAndDownload(videoName, pollInterval, callback), pollInterval);
        return;
      }

     // TODO: using the connected video should not happen - add the hash to the return and remove connected video
     const subtitlesResponse = await getSignedSubtitlesDownloadUrl(foundVideo.connectedVideo.hash, 'text/vtt');
     if (!subtitlesResponse.presignedUrl) {
      console.error('Error downloading subtitles, no url in response:', foundVideo.url);
      callback(null);
      return;
     }

     const res = await fetch(subtitlesResponse.presignedUrl, { method: 'GET' });
     if (!res.ok) {
       console.error('Error downloading subtitles:', foundVideo.url);
       // poll every second until we get a response or error
       callback(null);
       return;
     }

     const url = window.URL.createObjectURL(await res.blob());
     callback(url);
  } catch (err) {
      console.error('Error polling rendered video:', err);
  }

  callback(null);
}

const getPendingSubtitleTasks = async (): Promise<any[]> => {
  const info = await getSubtitlesTasks();
  if (info === null) {
      return [];
  }

  return info.filter((vid: any) => !taskCompleteStatuses.includes(vid.status));
}

const pollRenderedVideoAndDownload = async (videoName: string, pollInterval: number, callback: () => void) => {
  try {
      const info = await getRenderTasks();
      if (info === null) {
          callback();
          // if there is no info, something is wrong - the video was not rendered
          return;
      }

      const foundVideos = info.filter((vid: any) => vid.fileName === videoName);
      if (foundVideos.length === 0) {
        setTimeout(() => pollRenderedVideoAndDownload(videoName, pollInterval, callback), pollInterval);
        return;
      }
      const foundVideo = foundVideos[0];

      if (!taskCompleteStatuses.includes(foundVideo.status)) {
        // movie is still pending
        setTimeout(() => pollRenderedVideoAndDownload(videoName, pollInterval, callback), pollInterval);
        return;
      }

      const res = await fetch(foundVideo.url, { method: 'GET' });
      if (!res.ok) {
        console.error('Error downloading file:', foundVideo.url);
        // poll every second until we get a response or error
        //setTimeout(() => pollRenderedVideoAndDownload(videoName, pollInterval, callback), pollInterval);
        callback();
        return;
      }
      const url = window.URL.createObjectURL(await res.blob());

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'processed_video.mp4'; // Set the default filename
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
  } catch (err) {
      console.error('Error polling rendered video:', err);
  }

  callback();
}

const sendLoginTelemetry = (username: string, email: string, tenant: string, fullName: string, isAdmin: boolean) => {
  mixpanel.track('Sign In', {
    'Signin Type': 'Direct',
  });
  mixpanel.identify(email);
  mixpanel.people.set({
    $name: username,
    $email: email,
    tenant: tenant,
  });
}

function splitTextIntoLines(
  ctx: CanvasRenderingContext2D,
  text: string,
  maxWidth: number,
  maxLines?: number,
): {text: string, width: number}[] {
  const lines: {text: string, width: number}[] = [];
  const textSplitLines = text.split('\n');

  textSplitLines.forEach((splitLine: string) => {
    const words = splitLine.split(' ');
    let currentLine = '';
    let testWidth = 0;

    words.forEach((word) => {
      if (!word) {
        // add back spaces
        word = ' ';
      }
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      const metrics = ctx.measureText(testLine);
      testWidth = metrics.width;

      if (testWidth > maxWidth && currentLine) {
        lines.push({text: currentLine, width: ctx.measureText(currentLine).width});
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    });

    lines.push({text: currentLine, width: ctx.measureText(currentLine).width});
  });

  // Limit to 2 lines is standard convention - but we use 3
  return maxLines? lines.slice(0, maxLines) : lines;
}

const createTextImage = async (
  canvas: HTMLCanvasElement,
  text: string,
  style: any,
  width?: number,
  height?: number,
): Promise<[string, number, number]> => {
  return new Promise((resolve) => {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    // Set the font style and size based on the input style
    const fontSize = style.fontSize ? style.fontSize.toString() : '24px'; // Default font size
    const fontWeight = style.fontWeight ? style.fontWeight.toString() : 'normal'; // Default font weight
    const fontStyle = style.fontStyle ? style.fontStyle.toString() : 'normal'; // Default font style
    const fontFamily = style.fontFamily ? style.fontFamily : 'Arial'; // Default font family
    ctx.font = `${fontStyle} ${fontWeight} ${fontSize} ${fontFamily}`;

    // Measure the text dimensions (text width)
    const textWidth = ctx.measureText(text).width;
    const fontSizeNumber = parseInt(fontSize, 10); // Convert the font size to number
    const textHeight = fontSizeNumber; // Estimate text height (you can tweak the multiplier)

    // Resize the canvas to fit the text exactly
    canvas.width = width || Math.ceil(textWidth+25);
    canvas.height = height || Math.ceil(textHeight+15);

    // Re-set the font after resizing the canvas (as resizing clears the canvas)
    ctx.font = `${fontStyle} ${fontWeight} ${fontSize} ${fontFamily}`;

    // Set the background color if provided
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = style.backgroundColor || '#FFFFFF'; // Default to white if no background color
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Set text color
    ctx.fillStyle = style.color || '#000000'; // Default to black if no color is provided

    // Set text alignment (left, center, or right)
    const textAlign = style.textAlign || 'center'; // Default to center
    ctx.textAlign = textAlign as CanvasTextAlign;
    ctx.textBaseline = 'bottom';

    // Calculate x position based on alignment
    let x: number;
    if (textAlign === 'left') {
      x = 5; // Left-aligned: start at the left edge
    } else if (textAlign === 'right') {
      x = canvas.width - 5; // Right-aligned: start at the right edge but give some room (5px) so text isnt cut off
    } else {
      x = canvas.width / 2; // Center-aligned: center of the canvas
    }

    // Split the text into lines based on maxWidth
    const lines = splitTextIntoLines(ctx, text, canvas.width - 20);

    // Set vertical alignment (top, middle, or bottom)
    const verticalAlign = style.verticalAlign || 'middle'; // Default to middle
    if (verticalAlign === 'top') {
      ctx.textBaseline = 'top';
    } else if (verticalAlign === 'bottom') {
      ctx.textBaseline = 'bottom';
    } else {
      ctx.textBaseline = 'top';
    }

    const totalTextHeight = textHeight * lines.length;

    // Calculate the y position for the text based on vertical alignment
    let y: number;
    if (verticalAlign === 'top') {
      y = 5; // Top-aligned: start at the top edge
    } else if (verticalAlign === 'bottom') {
      y = canvas.height - 5; // Bottom-aligned: start at the bottom edge
    } else {
      y = (canvas.height - totalTextHeight) / 2; // Center-aligned: center of the canvas
    }

    const underlineHeight = 2; // Height of the underline
    const underlinePadding = 4; // Space between text and underline
    const drawUnderline = (context: any, xPos: number, yPos: number, lineWidth: number, padding: number) => {
      context.beginPath();
      context.strokeStyle = style.color || 'black'; // Underline color same as text color
      context.lineWidth = underlineHeight;
      ctx.moveTo(xPos, yPos + padding); // Start at the x minus text width
      ctx.lineTo(xPos + lineWidth, yPos + padding); // End at the text's width
      ctx.stroke();
    }

    // Draw each line of text
    lines.forEach((line, index) => {
      // Adjust the y position for each line based on the text height and alignment
      let yOffset = 0;
      if (verticalAlign === 'middle') {
        yOffset = index * textHeight;
        let xOffset: number;
        if (textAlign === 'left') {
          xOffset = 0;
        } else if (textAlign === 'right') {
          xOffset = -line.width;
        } else {
          xOffset = -line.width / 2;
        }
        if (line.text !== ' ' && style.textDecoration === 'underline') {
          drawUnderline(ctx, x + xOffset, y + yOffset + textHeight, line.width, 0);
        }
      } else if (verticalAlign === 'bottom') {
        yOffset = index * textHeight + (-textHeight * (lines.length - 1));
        let xOffset: number;
        if (textAlign === 'left') {
          xOffset = 0;
        } else if (textAlign === 'right') {
          xOffset = -line.width;
        } else {
          xOffset = -line.width / 2;
        }
        if (line.text !== ' ' && style.textDecoration === 'underline') {
          drawUnderline(ctx, x + xOffset, y - (lines.length - index - 1) * textHeight, line.width, 0);
        }
      } else {
        yOffset = index * textHeight;
        let xOffset: number;
        if (textAlign === 'left') {
          xOffset = 0;
        } else if (textAlign === 'right') {
          xOffset = -line.width;
        } else {
          xOffset = -line.width / 2;
        }
        if (line.text !== ' ' && style.textDecoration === 'underline') {
          drawUnderline(ctx, x + xOffset, yOffset + textHeight, line.width, 4);
        }
      }
      ctx.fillText(line.text, x, y + yOffset);
    });

    // Convert the canvas to a data URL
    const frame = canvas.toDataURL('image/png');

    // Resolve the image data URL and the width of the text
    resolve([frame, canvas.width, canvas.height]);
  });
};

interface DebouncedAction<T, P extends any[]> {
  ({ initialState, latestState }: { initialState: T | null, latestState: T | null }, ...args: P): void;
}

const createDebouncedActionWithInitialState = <T, P extends any[]>(
  action: DebouncedAction<T, P>,
  delay: number
) => {
  let initialState: T | null = null; // Holds initial state for the debounce period
  let latestState: T | null = null;  // Holds the most recent state

  // Debounced function that invokes the action with initial and latest states
  const debouncedFunction = debounce((...args: P) => {
    action({ initialState, latestState }, ...args);
    // Reset initial state for the next debounce cycle
    initialState = null;
  }, delay);

  // Function to trigger the debounced action with the current state
  const triggerDebouncedAction = (previousState: T, currentState: T, ...args: P) => {
    if (initialState === null) {
      initialState = previousState; // Capture initial state only once per cycle
    }
    latestState = currentState; // Update latest state continuously
    debouncedFunction(...args);
  };

  return triggerDebouncedAction;
};

export {
  taskCompleteStatuses,
  createTextImage,
  sendLoginTelemetry,
  pollRenderedVideoAndDownload,
  pollSubtitlesAndDownload,
  getPendingSubtitleTasks,
  calculateSha256,
  uploadVideo,
  blobUrlToUint8Array,
  createVideoFd,
  createDebouncedActionWithInitialState,
  throttle
}
