import { MediaLayer } from '../components/VideoLayers';

type Segment = {
  layers: MediaLayer[];
  start: number;
  end: number;
}

const rgbaToHex = (r: number, g: number, b: number, a: number) => {
  const toHex = (value: number) => value.toString(16).padStart(2, '0');
  return `0x${toHex(r)}${toHex(g)}${toHex(b)}${toHex(Math.round(a * 255))}`;
};

const serializeSegments = (segments: Segment[], canvasBackground: [number, number, number, number], canvasWidth: number, canvasHeight: number, videoWidth: number, videoHeight: number): string => {
  const canvasColorString = rgbaToHex(canvasBackground[0] * 255, canvasBackground[1] * 255, canvasBackground[2] * 255, canvasBackground[3]);
  const serialized = JSON.stringify({backgroundColor: canvasColorString, videoWidth, videoHeight, width: canvasWidth, height: canvasHeight, segments: segments.map((segment: Segment) => {
    return {...segment, layers: segment.layers.map((layer: MediaLayer) => {
      return {...layer, video: layer.video.getSerializable(), effectString: layer.video.getEffectString(true)};
    })
  }})})
  return serialized;
}

export {
  serializeSegments,
  type Segment
}