import React, { useState, useRef, useEffect, MouseEvent as ReactMouseEvent, useImperativeHandle, forwardRef, useCallback, useReducer } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { VideoFramesComponent } from './VideoFramesComponent';
import RGL, { WidthProvider, Layout } from 'react-grid-layout';
import { FrameSlider } from './FrameSlider';
import CommandHistory, { ResizeVideoCommand, DeleteVideoCommand, AddVideoCommand, debouncedMediaChangeHistoryAction, debouncedTextChangeHistoryAction } from './undo/UndoInterface';
import { pollSubtitlesAndDownload, calculateSha256, createVideoFd } from '../utils/utils';
import { downloadSubtitles, getVideoInfo, getSignedUploadUrl, getSignedSubtitlesUploadUrl, getSignedSubtitlesDownloadUrl, setVideoUploaded, presignedUrlResponse } from '../api/ServerApi';
import { v4 as uuidv4 } from 'uuid';
import { CloudUpload } from '@mui/icons-material';
import EffectSelector from './EffectSelector';
import MediaAddComponent from './MediaAddComponent';
import { uploadVideo, createTextImage, getPendingSubtitleTasks } from '../utils/utils';

import RightClickMenu from './RightClickMenu';
import VideoEditorControls from './VideoEditorControls';
import getEngine from './EffectsEngine';
import Video from './media/Video';
import Audio from './media/Audio';
import TextMedia from './media/Text';
import ImageMedia from './media/Image';
import Media from './media/Media';

import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';

import 'react-resizable/css/styles.css';
import './VideoLayers.css';

const Engine = await getEngine();

const ResponsiveGridLayout = WidthProvider(RGL);

type MediaLayer = {
  video: Media;
  rowNumber: number;
  playOffset: number;
  start: number;
  end: number;
}

interface CustomHandleProps {
    axis: 'w' | 'e';
    direction: 'left' | 'right';
    onResizeHandleMouseDown: (event: ReactMouseEvent<HTMLSpanElement>, axis: 'w' | 'e') => void;
}

// Custom handle component with types
const CustomHandle: React.FC<CustomHandleProps> = ({ axis, direction, onResizeHandleMouseDown }) => {
    return (
        <span
            className={`custom-handle custom-handle-${direction} react-resizable-handle react-resizable-handle-${axis}`}
            onMouseDown={(e: ReactMouseEvent<HTMLSpanElement>) => onResizeHandleMouseDown(e, axis)}
        />
    );
};

interface VideoLayersProps {
  currentTime: number;
  isRunning: boolean;
  onToggleSubtitles: () => void;
  onEffectSelected: (effectString: string) => void;
  onVideoAdded: (media: Video, isFirstMedia: boolean) => void;
  onLayersChanged: (layers: any[], totalDuration: number) => void;
  onSliderChange: (event: Event, newValue: number | number[]) => void;
  onLayerSelected: (layer: Media | null) => void;
  handlePlayPause: () => void;
  handleSkipFrame: (direction: 'forward' | 'backward') => void;
  onChangeBackground: (color: [number, number, number, number]) => void;
  setVideos: (videos: Media[]) => void;
  videos: Media[];
  selectedVideo: Media | null;
  canvasBackgroundColor: [number, number, number, number],
  isMobile: boolean;
  numColumns: number;
}

export interface VideoLayersRef {
  onTextCreated: (text: string, style: React.CSSProperties) => void;
  handleAddSubtitles: (_: any) => void;
  onSubtitlesUpload: (file: File) => void;
  onTextStyleChanged: (text: string, style: React.CSSProperties) => void;
  onMediaChanged: (media: Video, start: number | null, end: number | null) => void;
  onAddMedia: (file: File) => void;
}

const VideoLayers = forwardRef<VideoLayersRef, VideoLayersProps>((props, ref) => {
  const {
    currentTime,
    isRunning,
    onToggleSubtitles,
    onEffectSelected,
    onVideoAdded,
    onLayersChanged,
    onSliderChange,
    onLayerSelected,
    handlePlayPause,
    handleSkipFrame,
    onChangeBackground,
    setVideos,
    videos,
    selectedVideo,
    canvasBackgroundColor,
    isMobile,
    numColumns
  } = props;

  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [maxSliderOffset, setMaxSliderOffset] = useState(0);
  const [columnWidth, setColumnWidth] = useState(0);
  const [sliderHeight, setSliderHeight] = useState(0);
  const [layout, setLayout] = useState<Layout[]>([]);
  const [activeHandleDirection, setActiveHandleDirection] = useState<'w' | 'e' | null>(null);
  const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(false);
  const [videoFileLoadingInfo, setVideoFileLoadingInfo] = useState<any>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [sliderScrollPos, setSliderScrollPos] = useState(0);
  const [menuPosition, setMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({
    mouseX: null,
    mouseY: null,
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const [gridWidth, setGridWidth] = useState<number>(0);
  const [zoomValue, setZoomValue] = useState<number>(1);

  const beforeResizeVideosRef = useRef<any[]>([]);
  const dragRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<any>(null);

  const numFrames = isMobile ? 10 : 20;
  const minGridItemPixelSize = 40;
  const layersPadding = 15;

  const calculateColumnWidth = () => {
    if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth - 2*layersPadding;
        const widthPerColumn = containerWidth / numColumns;
        setColumnWidth(widthPerColumn);
    }
  };

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  useEffect(() => {
      const observer = new ResizeObserver(() => {
        calculateColumnWidth();
        setSliderHeight(containerRef.current.clientHeight);
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      // Add event listeners to the resize handles
      const leftHandles = document.querySelectorAll('.react-resizable-handle-w');
      leftHandles.forEach((handle: any) => {
          handle.addEventListener('mousedown', () => setActiveHandleDirection('w'));
          handle.addEventListener('touchstart', () => setActiveHandleDirection('w'));
      });

      const rightHandles = document.querySelectorAll('.react-resizable-handle-e');
      rightHandles.forEach((handle: any) => {
          handle.addEventListener('mousedown', () => setActiveHandleDirection('e'));
          handle.addEventListener('touchstart', () => setActiveHandleDirection('e'));
      });

      window.addEventListener('dragenter', handleDragEnter);
      window.addEventListener('drop', handleDrop);


      return () => {
        window.removeEventListener('resize', calculateColumnWidth);
        window.removeEventListener('dragenter', handleDragEnter);
        window.removeEventListener('drop', handleDrop);
      };
  }, [layout]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollBoxRef.current) {
        const scrollPercent = scrollBoxRef.current.scrollLeft / scrollBoxRef.current.scrollWidth;
        setSliderScrollPos(scrollPercent);
      }
    }

    const outerBox = scrollBoxRef.current;
    if (outerBox) {
      outerBox.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (outerBox) {
        outerBox.removeEventListener('scroll', handleScroll);
      }
    };
  }, [])

  useEffect(()=> {
  }, [canvasBackgroundColor])
  useEffect(()=> {
  }, [isRunning])
  useEffect(() => {
  }, [currentTime])
  useEffect(() => {
  }, [isMobile])
  useEffect(() => {
  }, [videos])

  useEffect(() => {
    if (selectedVideo instanceof TextMedia) {
      onTextStyleChanged(selectedVideo.text, selectedVideo.style);
    }
  }, [selectedVideo?.scaledWidth, selectedVideo?.scaledHeight])

  useEffect(() => {
    // We keep the current video index on the selectedVideoIndex because we need to have this updated in real time
    // as some of the other components (EffectSelector for example) can trigger a function of our component when they
    // are already aware of the new videoIndex... If we use a separate state to set index it may come too late.
    // We need to force any UI components that depend on a new selection to also re-render so we force it here
    forceUpdate();
  }, [selectedVideo])

  const createImageMedia = async (image: string) => {
    const img = new Image();
    img.src = image;
 
    img.onload = () => {
      const row = videos.reduce((max: number, v: Media) => v.row > max ? v.row : max, -1) + 1;
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
 
      if (ctx) {
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, img.width, img.height);
      }
 
      fetch(image).then(async (res) => {
        const imageBlob = await res.blob();
        const textComponent: ImageMedia = new ImageMedia({
          name: "Image",
          videoRef: canvas,
          duration: totalDuration ? 0.25 * totalDuration : 10,
          left: 0,
          leftBackgroundOffset: 0,
          rightBackgroundOffset: 0,
          startBase: 0,
          endBase: 0,
          isResizing: false,
          leftShrink: 0,
          rightShrink: 0,
          row: row,
          framesOffset: 0,
          playOffset: 0,
          effects: [],
          dirty: false,
          height: img.height,
          width: img.width,
          url: image,
          type: imageBlob.type,
          sha256: '',
          scaledHeight: null,
          scaledWidth: null,
          baseWidth: 0,
          baseHeight: 0,
          x: 0,
          y: 0,
          rotation: 0,
          crop: { left: 0, right: 0, top: 0, bottom: 0 }
        });

        calculateSha256(imageBlob).then((sha256: string) => {
          textComponent.sha256 = sha256;
          debouncedMediaUpload(textComponent.name, textComponent.type, textComponent.sha256, textComponent.url);
          const newVideos = pushNewMedia(textComponent);
          setVideos(newVideos);

          if (videos.length === 0) {
            setTotalDuration(textComponent.duration);
          }

          // Select the Image element
          onLayerSelected(textComponent as unknown as Video);
        });
      });
    };
  };

  const onAddMedia = async (file: File) => {

    mixpanel.track('fileDropped', {
      'numberOfVideos': videos.length,
      'name': file.name,
      'size': file.size,
      'type': file.type,
    });

    if (isLoadingVideo) {
      // we currently do not support loading videos at the same time
      return;
    }

    // TODO: for now disable this until we have the feature ready
    if (file && file.type.startsWith('image/')) {
      const imageURL = URL.createObjectURL(file);
      createImageMedia(imageURL);
    }
    if (file && (file.type.startsWith('video/') || file.type.startsWith('audio/'))) {
    //else if (file && file.type.startsWith('video/')) {
      setIsLoadingVideo(true);
      setVideoFileLoadingInfo({name: file.name, size: file.size});
      const videoURL = URL.createObjectURL(file);

      const video = videoRef.current;
      video.src = videoURL;
      video.name = file.name;

      video.onloadedmetadata = async () => {

        mixpanel.track('videoMetadataLoaded', {
          'numberOfVideos': videos.length,
          'name': video.name,
          'size': file.size,
          'type': file.type,
          'src': video.src,
          'duration': video.duration,
        });

        const duration = video.duration;
        const row = videos.reduce((max: number, v: Media) => v.row > max ? v.row : max, -1) + 1;
        const storageFilePath = videoURL.split('/').splice(-1) + '.bmp';
        let newVideoObject: Video | Audio;
        if (file.type.startsWith('video/')) {
          newVideoObject = new Video({name: video.name, videoRef: null, videoFdRef: null, framePendingTime: null, url: videoURL, duration, left: 0, leftBackgroundOffset: 0, rightBackgroundOffset: 0,
            startBase: 0, endBase: 0, isResizing: false, leftShrink: 0, rightShrink: 0, row: row, framesOffset: 0, playOffset: 0,
            effects: [], isPlaying: false, curVideoTime: 0, dirty: false, height: video.videoHeight, width: video.videoWidth,
            storageFilePath: storageFilePath, storageBuffer: null, scaledHeight: null, scaledWidth: null, baseWidth: 0, baseHeight: 0,
            x: 0, y: 0, rotation: 0, type: file.type, crop: {left: 0, right: 0, top: 0, bottom: 0}, sha256: '',
          });
        } else {
          newVideoObject = new Audio({name: video.name, videoRef: null, videoFdRef: null, framePendingTime: null, url: videoURL, duration, left: 0, leftBackgroundOffset: 0, rightBackgroundOffset: 0,
            startBase: 0, endBase: 0, isResizing: false, leftShrink: 0, rightShrink: 0, row: row, framesOffset: 0, playOffset: 0,
            effects: [], isPlaying: false, curVideoTime: 0, dirty: false, height: video.videoHeight, width: video.videoWidth,
            storageFilePath: storageFilePath, storageBuffer: null, scaledHeight: null, scaledWidth: null, baseWidth: 0, baseHeight: 0,
            x: 0, y: 0, rotation: 0, type: file.type, crop: {left: 0, right: 0, top: 0, bottom: 0}, sha256: '',
          });
        }

        const fd = await createVideoFd(newVideoObject);

        newVideoObject.videoFdRef = fd;
        const hasVideoComponent = file.type.startsWith('video/');
        const hasAudioComponent = await Engine.has_audio(fd);
        newVideoObject.hasAudioComponent = hasAudioComponent;
        newVideoObject.hasVideoComponent = hasVideoComponent;
        calculateSha256(file).then((sha256: string) => {
          newVideoObject.sha256 = sha256;
          getVideoInfo(sha256).then((videoInfo: any) => {
            newVideoObject.subtitlesLoaded = videoInfo ? !!videoInfo.subtitlesUrl : false;
            newVideoObject.subtitlesActive = newVideoObject.subtitlesLoaded;

            const newVideos = pushNewMedia(newVideoObject);
            const maxDuration = Math.max(...newVideos.map((video) => video.duration));
            // normalize all video attributes according to the scale change due to changing the maxDuration (the scale of the movies is according to max movie)
            const normalizedVideos = newVideos.map((media: Media) => {
              // Calculate the normalizer
              const normalizer = media.duration / maxDuration;

              media.left = +(media.left * normalizer).toFixed(3);
              media.leftBackgroundOffset = +(media.leftBackgroundOffset * normalizer).toFixed(3);
              media.rightBackgroundOffset = +(media.rightBackgroundOffset * normalizer).toFixed(3);
              media.startBase = +(media.startBase * normalizer).toFixed(3);
              media.endBase = +(media.endBase * normalizer).toFixed(3);
              media.leftShrink = +(media.leftShrink * normalizer).toFixed(3);
              media.rightShrink = +(media.rightShrink * normalizer).toFixed(3);

              return media;
            });

            const originalVideos = videos.map((vid: Media) => vid.deepCopy());
            const addVideosCommand = new AddVideoCommand(originalVideos as Video[], normalizedVideos, newVideos.length-1, (newVideos: Video[]) => {
              setVideos(newVideos);
            });

            CommandHistory.push(addVideosCommand);

            setVideos(normalizedVideos);
            setTotalDuration(maxDuration);
          })
        })
      };
    }
  };

  const handleResizeStart = (layout: any, oldItem: any, newItem: any, placeholder: any, e: any) => {
    const index = videos.findIndex((video: Media) => video.id === oldItem.i) ;
    if (videos[index].isResizing) {
        console.error("resizing while still in resizing!")
    }

    beforeResizeVideosRef.current = videos.map((vid: Media) => vid.deepCopy());

    const newVideos = [...videos];
    newVideos[index].isResizing = true;
    setVideos(newVideos);
  }

  const getAdjacentLayoutItem = (item: any, direction: 'left' | 'right') => {
    let foundItem: any = null;
    for (const candidate of layout) {
      if (candidate.i == item.i || candidate.y != item.y) {
        continue;
      }
      if (direction == 'left') {
        const candidateRight = candidate.x + candidate.w;
        if (candidateRight <= item.x && (!foundItem || candidateRight >= foundItem.x + foundItem.w) ) {
          foundItem = candidate;
        }
      } else {
        const candidateLeft = candidate.x;
        if (candidateLeft >= item.x + item.w && (!foundItem || candidateLeft <= foundItem.x) ) {
          foundItem = candidate;
        }
      }
    }

    return foundItem;
  }

  const getLayoutItemToTheRight = (item: any) => {
    return getAdjacentLayoutItem(item, 'right');
  }

  const getLayoutItemToTheLeft = (item: any) => {
    return getAdjacentLayoutItem(item, 'left');
  }

  const calculateOffsets = (oldItem: any, newItem: any, isFinalResize: boolean = false, allowInfiniteIncrease: boolean = false) => {
    const index = videos.findIndex((video: Media) => video.id === oldItem.i) ;

    let leftBackgroundOffset = null;
    let rightBackgroundOffset = null;
    let maxW = null;

    const increaseAmount = newItem.w - oldItem.w;

    // the right side
    if (activeHandleDirection === 'e') {
        if (increaseAmount > videos[index].rightShrink && !allowInfiniteIncrease) {
          rightBackgroundOffset = 0;
          maxW = oldItem.w + videos[index].rightShrink;
        } else {
          rightBackgroundOffset = oldItem.w - newItem.w + videos[index].endBase
          if (!isFinalResize) {
            const item = getLayoutItemToTheRight(oldItem);
            if (item) {
              const itemLeft = item.x;
              const oldItemRight = oldItem.x + oldItem.w;
              if (allowInfiniteIncrease) {
                // increase up to the adjacent element
                maxW = oldItem.w + (itemLeft - oldItemRight);
              } else {
                // cant increase further than adjacent element && no increase beyond the right shrink
                maxW = Math.min(oldItem.w + (itemLeft - oldItemRight), oldItem.w + videos[index].rightShrink);
              }
            }
          } else {
            maxW = oldItem.w + videos[index].rightShrink + videos[index].leftShrink;
          }
        }
    }
    // the left side
    else {
        if (increaseAmount > videos[index].leftShrink && !allowInfiniteIncrease) {
            leftBackgroundOffset = 0;
            maxW = oldItem.w + videos[index].leftShrink;
        } else {
            leftBackgroundOffset = newItem.w - oldItem.w + videos[index].startBase;
            if (!isFinalResize) {
              const item = getLayoutItemToTheLeft(oldItem);
              if (item) {
                const itemRight = item.x + item.w;
                if (allowInfiniteIncrease) {
                  // increase up to the adjacent element
                  maxW = oldItem.w + (oldItem.x - itemRight);
                } else {
                  // cant increase further than adjacent element && no increase beyond the left shrink
                  maxW = Math.min(oldItem.w + (oldItem.x - itemRight), oldItem.w + videos[index].leftShrink);
                }
              }
            } else {
              maxW = oldItem.w + videos[index].rightShrink + videos[index].leftShrink;
            }
        }
    }

    return [leftBackgroundOffset, rightBackgroundOffset, maxW];
  }

  const handleResizeStop = (layout: any, oldItem: any, newItem: any) => {
    const index = videos.findIndex((video: Media) => video.id === oldItem.i) ;
    if (!videos[index].isResizing) {
        console.error("resizing without having isResizing enabled")
        newItem.w = oldItem.w;
        newItem.x = oldItem.x;
        return;
    }

    // Handle images and other kinds that do not need a background
    if (!(videos[index] instanceof Video) && !(videos[index] instanceof Audio)) {
      const newVideos = [...videos];
      newVideos[index].duration = newItem.w / numColumns * totalDuration;
      newVideos[index].left = newItem.x;
      newVideos[index].isResizing = false;
      setVideos(newVideos);
      return;
    }

    const [leftBackgroundOffset, rightBackgroundOffset, maxW] = calculateOffsets(oldItem, newItem, true);
    const newMaxW = maxW !== null ? maxW : newItem.maxw;

    // For some reason newItem.maxw can be undefined? this causes a bug if we dont check
    if (newMaxW) {
      newItem.maxW = newMaxW;
    }

    const newVideos = [...videos];
    if (leftBackgroundOffset !== null) {
        newVideos[index].leftBackgroundOffset = (leftBackgroundOffset);
        newVideos[index].startBase = leftBackgroundOffset;
        // shrinked from left
        if (oldItem.x < newItem.x && oldItem.w > newItem.w) {
            newVideos[index].leftShrink = -leftBackgroundOffset;
            newVideos[index].left = newItem.x;
        }
        // increase the left side
        if (newItem.x < oldItem.x && newItem.w > oldItem.w) {
            newVideos[index].leftShrink = -leftBackgroundOffset;
            newVideos[index].left = newItem.x;
        }
    }
    if (rightBackgroundOffset !== null) {
        newVideos[index].rightBackgroundOffset = (rightBackgroundOffset);
        newVideos[index].endBase = rightBackgroundOffset;
        // shrinked from right
        if (oldItem.x == newItem.x && oldItem.w > newItem.w) {
            newVideos[index].rightShrink = rightBackgroundOffset;
        }
        // increase the right side
        if (newItem.x == oldItem.x && newItem.w > oldItem.w) {
            newVideos[index].rightShrink = rightBackgroundOffset;
        }
    }

    newVideos[index].isResizing = false;

    // TODO: really don't like this - part of playing logic in a resize callback
    newVideos[index].playOffset = (newVideos[index].leftShrink / numColumns) * totalDuration + newVideos[index].framesOffset;
    // set as dirty so it gets seeked to the offset;
    newVideos[index].dirty = true;

    const resizeCommand = new ResizeVideoCommand(beforeResizeVideosRef.current as Video[], newVideos, (newVideos: Video[]) => {
      setVideos(newVideos)
    });

    CommandHistory.push(resizeCommand);

    setVideos(newVideos);
  }

  const handleResize = (layout: any, oldItem: any, newItem: any, placeholder: any, e: any) => {
    const index = videos.findIndex((video: Media) => video.id === oldItem.i) ;
    if (!(videos[index] instanceof Video) && !(videos[index] instanceof Audio)) {
      const [_, __, maxW] = calculateOffsets(oldItem, newItem, false, true);
      if (maxW) {
        newItem.maxW = Math.ceil(maxW);
        newItem.w = Math.min(maxW, newItem.w);
      }
      const newVideos = [...videos];
      newVideos[index].duration = newItem.w / numColumns * totalDuration;
      setVideos(newVideos);
      return;
    }
    const [leftBackgroundOffset, rightBackgroundOffset, maxW] = calculateOffsets(oldItem, newItem);

    const newMaxW = maxW !== null ? maxW : newItem.maxw;
    // For some reason newItem.maxw can be undefined? this causes a bug if we dont check
    if (newMaxW) {
      newItem.maxW = newMaxW;
    }

    if (leftBackgroundOffset === null && rightBackgroundOffset === null) {
        newItem.x = oldItem.x;
        newItem.w = oldItem.w;
        return;
    }

    const newVideos = [...videos];
    if (leftBackgroundOffset !== null) {
        newVideos[index].leftBackgroundOffset = (leftBackgroundOffset);
    }
    if (rightBackgroundOffset !== null) {
        newVideos[index].rightBackgroundOffset = (rightBackgroundOffset);
    }

    setVideos(newVideos);
  };

  const handleDragStart = (layout: any, oldItem: any, newItem: any, placeholder: any, e: any) => {
    beforeResizeVideosRef.current = videos.map((vid: Media) => vid.deepCopy());
  }

  const handleDragStop = (layout: any, oldItem: any, newItem: any) => {
    const newVideos = [...videos];
    const index = videos.findIndex((video: Media) => video.id === oldItem.i) ;
    newVideos[index].left = (newItem.x);
    newVideos[index].row = (newItem.y);

    if (oldItem.x !== newItem.x || oldItem.w !== newItem.w || oldItem.y !== newItem.y) {
      // add undo action only if there was a change in position
      const resizeCommand = new ResizeVideoCommand(beforeResizeVideosRef.current as Video[], newVideos, (newVideos: Video[]) => {
        setVideos(newVideos)
      });
      CommandHistory.push(resizeCommand);
    }

    setVideos(newVideos);
  }

  const calculateConversionMetadata = (video: Media): MediaLayer => {
    const playCut = +((video.leftShrink / numColumns) * totalDuration + (video.rightShrink / numColumns) * totalDuration).toFixed(3);
    const videoStart = +((video.left / numColumns) * totalDuration).toFixed(3);
    const videoEnd = +(videoStart + video.duration - playCut).toFixed(3);
    return {
      video: video,
      rowNumber: video.row,
      playOffset: video.playOffset,
      start: videoStart,
      end: videoEnd,
    };
  }

  const convertLayoutToLayers = (layout: Layout[]) => {
    return layout.map((l: Layout) => calculateConversionMetadata(videos[videos.findIndex((video: Media) => video.id === l.i)]));
  }

  const handleLayoutChange = (currentLayout: Layout[]) => {
    setLayout(currentLayout);
    const layers = convertLayoutToLayers(currentLayout);
    const maxVideoEnd = Math.max(...layers.map((layer) => layer.end));
    setMaxSliderOffset(maxVideoEnd);

    onLayersChanged(layers, totalDuration);
  }

  const onLoadedVideo = (video: Video, frames: any[], audioFrames: any[], index: number) => {
    setIsLoadingVideo(false);
    setVideoFileLoadingInfo(null);
    const isNew = !(videos[index].onLoadedCalled === true);
    video.onLoadedCalled = true;
    // update the video track frames with the loaded frames
    video.frames = frames;
    video.audioFrames = audioFrames;
    // set volume: muting overrides the set volume
    video.videoRef.volume = video.isMuted ? 0 : video.volume;
    video.videoRef.playbackRate = video.speed;

    if (!isNew) {
      const selectedIndex = videos.findIndex((vid: Media) => vid.id === video.id) ;
      if (selectedIndex === -1) {
        // this could happen if the user deletes the video while it's loading
        return;
      }

      const newVideos = [...videos];
      newVideos[selectedIndex] = video;
      setVideos(newVideos);

    } else {
      // TODO: type should be from the server
      getSignedSubtitlesDownloadUrl(video.sha256, 'text/vtt').then((subtitlesUrl: presignedUrlResponse) => {
        if (subtitlesUrl.presignedUrl) {
          fetch(subtitlesUrl.presignedUrl).then(async (res) => {
            if (res.ok) {
              const url = window.URL.createObjectURL(await res.blob());
              const trackElement = document.createElement('track');
              trackElement.kind = 'subtitles';
              trackElement.label = 'English';
              trackElement.srclang = 'en';
              trackElement.src = url;
              trackElement.default = true;

              const selectedIndex = videos.findIndex((vid: Media) => vid.id === video.id) ;
              if (selectedIndex === -1) {
                // this could happen if the user deletes the video while it's loading
                return;
              }

              const curVideo = videos[selectedIndex];

              if (!(curVideo instanceof Video || curVideo instanceof Audio)) {
                // should not happen - subtitles request for non video / audio type
                return;
              }

              // Append the track to the video element
              curVideo.videoRef.appendChild(trackElement);

              curVideo.subtitlesActive = true;
              curVideo.subtitlesLoaded = true;

              const newVideos = [...videos];
              newVideos[selectedIndex] = curVideo;
              setVideos(newVideos);
            }
          })
        }
      })
      getPendingSubtitleTasks().then((pendingSubtitleTasks: any[]) => {
        if (pendingSubtitleTasks.length) {
          video.downloadingSubtitles = true;
          pollSubtitlesAndDownload(pendingSubtitleTasks[0].fileName, 1000, async (url: string | null) => {
            if (url) {
              const selectedIndex = videos.findIndex((vid: Media) => vid.id === video.id) ;
              if (selectedIndex === -1) {
                // this could happen if the user deletes the video while it's loading
                return;
              }

              // Create a new <track> element for subtitles
              const trackElement = document.createElement('track');
              trackElement.kind = 'subtitles';
              trackElement.label = 'English';
              trackElement.srclang = 'en';
              trackElement.src = url;
              trackElement.default = true;

              const curVideo = videos[selectedIndex];

              if (!(curVideo instanceof Video || curVideo instanceof Audio)) {
                // should not happen - subtitles request for non video / audio type
                return;
              }

              // Append the track to the video element
              curVideo.videoRef.appendChild(trackElement);
              curVideo.subtitlesLoaded = true;
              curVideo.downloadingSubtitles = false;
              curVideo.subtitlesActive = true;

              const newVideos = [...videos];
              newVideos[selectedIndex] = curVideo;
              setVideos(newVideos);

              // Clean up Blob URL when not needed anymore
              window.addEventListener('unload', () => {
                URL.revokeObjectURL(url);
              });
            }
          })
        }

        const selectedIndex = videos.findIndex((vid: Media) => vid.id === video.id) ;
        if (selectedIndex === -1) {
          // this could happen if the user deletes the video while it's loading
          return;
        }

        const newVideos = [...videos];
        newVideos[selectedIndex] = video;
        setVideos(newVideos)

        const isFirstMedia = videos.filter((vid: Media) => vid.id !== video.id).length === 0;
        onVideoAdded(video, isFirstMedia);
      })
    }
  }

  const onVideoUploadComplete = (video: Media, newlyUploaded: boolean) => {
    if (newlyUploaded) {
      mixpanel.track('VideoUploaded', {
        'name': video.name,
        // TODO: need to add the signed url
      });
    }

    setVideoUploaded(video.sha256);

    const selectedIndex = videos.findIndex((vid: Media) => vid.id === video.id) ;
    if (selectedIndex === -1) {
      // this could happen if the user deletes the media while it's loading
      return;
    }

    const newVideos = [...videos];
    newVideos[selectedIndex].uploadComplete = true;
    setVideos(newVideos)
  }

  const handleDelete = async () => {

    mixpanel.track('VideoDelete', {
      'numberOfVideos': videos.length,
    });

    if (selectedVideo === null) {
      return;
    }

    // TODO: for the undo function - when we do several undos we can delete movies and because we restore from a snapshot of videos some of them have old fds that may be closed...
    // need to deal with that before we can close / open using c code handles... for now we dont close resources...
    //if (!!(selectedVideo instanceof Video) as boolean || !!(selectedVideo instanceof Audio)) {
    //  const fd = (selectedVideo as Video).videoFdRef;
    //  if (fd !== null) {
    //    await Engine.close_movie([(fd as unknown) as string]).then((res: number) => {
    //      if (res === -1) {
    //        console.error('failed to close movie');
    //      }
    //    })
    //  }
    //}
    const selectedIndex = videos.findIndex((video: Media) => video.id === selectedVideo.id) ;
    const updatedVideos = [...videos];
    updatedVideos.splice(selectedIndex, 1);

    // the maximum end time of all media
    const newTotalDuration = updatedVideos.length ? Math.max(...updatedVideos.map((video) => video.left * totalDuration / numColumns + video.duration)) : 10;

    const originalVideos = videos.map((vid: Media) => vid.deepCopy());
    const deleteVideosCommand = new DeleteVideoCommand(originalVideos as Video[], updatedVideos, selectedIndex, totalDuration, newTotalDuration, (newVideos: Video[], newDuration: number) => {
      setVideos(newVideos);
      setTotalDuration(newDuration);
    });

    CommandHistory.push(deleteVideosCommand);

    onLayerSelected(null);

    // resize the tracks to the full video length
    setTotalDuration(newTotalDuration);

    if (updatedVideos.length === 0) {
      // for some reason in mobile when last video is deleted there is no callback
      // this is a hack to fix it...
      handleLayoutChange([]);
    }
    setVideos(updatedVideos);
  }

  const handleToggleSound = async () => {

    mixpanel.track('VideoSound', {
      'numberOfVideos': videos.length,
    });

    const selectedIndex = videos.findIndex((video: Media) => video.id === selectedVideo?.id) ;

    if (selectedIndex === -1) {
      return;
    }

    const curVideo = videos[selectedIndex].deepCopy();
    if (curVideo === null) {
      return;
    }

    if (!(curVideo instanceof Video)) {
      return;
    }

    if (!curVideo.hasAudioComponent) {
      // No audio... do nothing
      return;
    }

    const newVideos = [...videos];
    const isCurrentlyMuted = curVideo.isMuted;
    curVideo.isMuted = !isCurrentlyMuted;
    curVideo.videoRef.volume = isCurrentlyMuted ? 1 : 0;
    newVideos[selectedIndex] = curVideo;

    const originalVideos = videos.map((vid: Media) => vid.deepCopy());
    const addVideosCommand = new AddVideoCommand(originalVideos as Video[], newVideos, selectedIndex, (newVideos: Video[]) => {
      newVideos[selectedIndex].videoRef.volume = newVideos[selectedIndex].isMuted ? 0 : 1;
      setVideos(newVideos);
    });

    CommandHistory.push(addVideosCommand);

    setVideos(newVideos);
  }

  const downloadSubtitlesForVideo = (video: Video) => {
    downloadSubtitles(video.sha256).then(({videoName}) => {
      mixpanel.track('Add Subtitles Server Response', {
        'name': videoName
      });
      pollSubtitlesAndDownload(videoName, 1000, async (url: string | null) => {
        if (url) {
          // Create a new <track> element for subtitles
          const trackElement = document.createElement('track');
          trackElement.kind = 'subtitles';
          trackElement.label = 'English';
          trackElement.srclang = 'en';
          trackElement.src = url;
          trackElement.default = true;

          const newVideos = [...videos];
          const videoIndex = newVideos.findIndex(vid => vid.id == video.id);

          const curVideo = videos[videoIndex];
          if (!(curVideo instanceof Video) && !(curVideo instanceof Audio)) {
            return;
          }

          if (videoIndex !== -1) {
            // Append the track to the video element
            curVideo.videoRef.appendChild(trackElement);
            curVideo.subtitlesLoaded = true;
            curVideo.downloadingSubtitles = false;
            curVideo.subtitlesActive = true;
            newVideos[videoIndex] = curVideo;
            setVideos(newVideos);
          }

          // Clean up Blob URL when not needed anymore
          window.addEventListener('unload', () => {
            URL.revokeObjectURL(url);
          });
        }
      })
    }).catch((err: any) => {
      console.error(err);
    })
  }

  const handleAddSubtitles = async () => {
    if (selectedVideo === null) {
      return;
    }
    if (!(selectedVideo instanceof Video) as boolean && !(selectedVideo instanceof Audio)) {
      return;
    }

    const newVideos = [...videos];

    const selectedIndex = videos.findIndex((video: Media) => video.id === selectedVideo.id) ;
    const curVideo = selectedVideo.deepCopy();
    if ((curVideo as Video).subtitlesLoaded) {
      (curVideo as Video).subtitlesActive = !(curVideo as Video).subtitlesActive;

      newVideos[selectedIndex] = curVideo;

      const originalVideos = videos.map((vid: Media) => vid.deepCopy());
      const addVideosCommand = new AddVideoCommand(originalVideos as Video[], newVideos, selectedIndex, (newVideos: Video[]) => {
        newVideos[selectedIndex].videoRef.volume = newVideos[selectedIndex].isMuted ? 0 : 1;
        setVideos(newVideos);
      });

      CommandHistory.push(addVideosCommand);

      setVideos(newVideos);
    } else {
      (curVideo as Video).downloadingSubtitles = true;
      newVideos[selectedIndex] = curVideo;
      setVideos(newVideos);
      downloadSubtitlesForVideo(curVideo as Video);
    }

    onToggleSubtitles();
  }

  const onSubtitlesUpload = (file: File) => {

    mixpanel.track('SubtitlesUpload', {
      'numberOfVideos': videos.length,
      'name': file.name,
      'size': file.size,
      'type': file.type,
    });

    const selectedIndex = videos.findIndex((video: Media) => video.id === selectedVideo?.id) ;

    if (selectedIndex === -1) {
      return;
    }

    const curVideo = videos[selectedIndex];

    if (!(curVideo instanceof Video) && !(curVideo instanceof Audio)) {
      return;
    }

    const url = URL.createObjectURL(file);

    getSignedSubtitlesUploadUrl(curVideo.name, file.type, curVideo.sha256).then( async (uploadInformation: presignedUrlResponse) => {
      if (uploadInformation.presignedUrl) {
        const file = await (await fetch(url)).blob();
        // upload the video
        uploadVideo(uploadInformation.presignedUrl, file, file.type, () => {});

        const trackElement = document.createElement('track');
        trackElement.kind = 'subtitles';
        trackElement.label = 'English';
        trackElement.srclang = 'en';
        trackElement.src = url;
        trackElement.default = true;

        // Append the track to the video element
        curVideo.videoRef.appendChild(trackElement);

        // TODO: should wait till the upload is over
        curVideo.subtitlesLoaded = true;

        curVideo.subtitlesActive = !curVideo.subtitlesActive;

        const newVideos = [...videos];
        newVideos[selectedIndex] = curVideo;
        setVideos(newVideos);
      }
    })
  }

  const splitFrameImage = async (frameUrl: string, weight: number) => {
    // Create an image element and load the Blob URL
    const image = new Image();
    image.src = frameUrl;

    return new Promise<[string, string]>((resolve, reject) => {
      image.onload = () => {
        // Create a canvas to manipulate the image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          return reject(new Error('Canvas context not found'));
        }

        // Set canvas width and height to match the image dimensions
        const { width, height } = image;
        canvas.width = width;
        canvas.height = height;
 
        // Calculate the cut point (weight is the fraction)
        const cutWidth = Math.floor(width * weight);

        // Create two new canvases for the two parts
        const firstCanvas = document.createElement('canvas');
        const secondCanvas = document.createElement('canvas');
 
        // Set the dimensions for both canvases
        firstCanvas.width = cutWidth;
        firstCanvas.height = height;
        secondCanvas.width = width - cutWidth;
        secondCanvas.height = height;
 
        const firstCtx = firstCanvas.getContext('2d');
        const secondCtx = secondCanvas.getContext('2d');
 
        if (!firstCtx || !secondCtx) {
          return reject(new Error('Canvas context not found'));
        }
 
        // Draw the first part of the image on the first canvas
        firstCtx.drawImage(image, 0, 0, cutWidth, height, 0, 0, cutWidth, height);
 
        // Draw the second part of the image on the second canvas
        secondCtx.drawImage(image, cutWidth, 0, width - cutWidth, height, 0, 0, width - cutWidth, height);
 
        // Convert both canvases to Blob URLs
        firstCanvas.toBlob((firstBlob) => {
          if (!firstBlob) return reject(new Error('Failed to create Blob from the first part'));
          const firstBlobUrl = URL.createObjectURL(firstBlob);
 
          secondCanvas.toBlob((secondBlob) => {
            if (!secondBlob) return reject(new Error('Failed to create Blob from the second part'));
            const secondBlobUrl = URL.createObjectURL(secondBlob);
 
            // Resolve with both Blob URLs (but no cleanup of Blob URLs)
            resolve([firstBlobUrl, secondBlobUrl]);
 
            // Cleanup: Simply free up the canvases by resetting their width and height
            firstCanvas.width = 0;
            firstCanvas.height = 0;
            secondCanvas.width = 0;
            secondCanvas.height = 0;
 
          });
        });
      };
 
      // Handle errors in loading the image
      image.onerror = reject;
    });
  };

  const handleCut = async (cutPosition: number) => {
    const selectedIndex = videos.findIndex((video: Media) => video.id === selectedVideo?.id);

    if (selectedIndex === -1) {
      return;
    }
    const video = videos[selectedIndex];
    const updatedVideos = [...videos];

    const videoStart = video.left;
    const videoEnd = video.left + (video.duration / totalDuration) * numColumns;

    mixpanel.track('VideoCut', {
      'numberOfVideos': videos.length,
      'name': video.name
    });

    // If the cutPosition is within the video bounds
    if (cutPosition > videoStart && cutPosition < videoEnd) {
      const shrinkeddDuration = +(video.leftShrink / numColumns * totalDuration);
      const firstPartDuration = +((cutPosition - (videoStart - video.leftShrink)) / numColumns * totalDuration);
      const secondPartDuration = +(video.duration - firstPartDuration);

      const numVideoFramesFraction = numFrames * (video.duration / totalDuration);
      const numVideoFrames = Math.ceil(numVideoFramesFraction);

      // TODO: on exact cut this doesnt work... fix it
      // offset the timevalue according to the already cut time of the existing cut frame 
      let framesOffset = (firstPartDuration - video.frameCutOffset / numVideoFramesFraction * video.duration) / video.duration * numVideoFramesFraction + Number(video.frameCutOffset!==0);
      const firstPartFramesCount = Math.floor(framesOffset);
      const secondPartFramesCount = Math.floor(numVideoFrames - framesOffset);

      const firstPartFrameFraction = framesOffset - Math.floor(framesOffset);
      const secondPartFrameFraction = 1 - firstPartFrameFraction;

      let firstPartFrame = null;
      let firstPartAudioFrame = null;
      let secondPartFrame = null;
      let secondPartAudioFrame = null;
      if (video.frames && video.frames.length) {
        const [firstVideoUrl, secondVideoUrl] = await splitFrameImage(video.frames[firstPartFramesCount].url, +firstPartFrameFraction);
        firstPartFrame = { ...(video.frames[firstPartFramesCount]), weight: firstPartFramesCount ? firstPartFrameFraction * 10 : 10, url: firstVideoUrl};
        secondPartFrame = { ...(video.frames[firstPartFramesCount]), weight: secondPartFramesCount ? secondPartFrameFraction * 10 : 10, url: secondVideoUrl};
      }
      if (video.audioFrames && video.audioFrames.length) {
        const [firstAudioUrl, secondAudioUrl] = await splitFrameImage(video.audioFrames[firstPartFramesCount].url, firstPartFrameFraction);
        firstPartAudioFrame = { ...(video.audioFrames[firstPartFramesCount]), weight: firstPartFramesCount ? firstPartFrameFraction * 10 : 10, url: firstAudioUrl};
        secondPartAudioFrame = { ...(video.audioFrames[firstPartFramesCount]), weight: secondPartFramesCount ? secondPartFrameFraction * 10 : 10, url: secondAudioUrl};
      }

      const firstVideo: Video = new Video({
        ...video.deepCopy(),
        playOffset: +video.playOffset,
        framesOffset: +video.framesOffset,
        duration: firstPartDuration,
        endBase: 0,
        rightBackgroundOffset: 0,
        rightShrink: 0,
        row: video.row,
        dirty: true,
        frames: video.frames.length ? [...video.frames.slice().splice(0, firstPartFramesCount), firstPartFrame] : [],
        audioFrames: video.audioFrames.length ? [...video.audioFrames.slice().splice(0, firstPartFramesCount), firstPartAudioFrame] : [],
      });

      // TODO: should change time of first and second videos
      const secondVideo: Video = new Video({
        ...video.deepCopy(),
        id: uuidv4(),
        left: cutPosition+1, // TODO: this causes small black spots in video
        // any left truncated or previously cut parts + total duration of first part including left truncated - left truncated = left truncated / cut + total duration of first without cuts
        playOffset: +(video.playOffset + firstPartDuration - shrinkeddDuration),
        framesOffset: +(video.framesOffset + firstPartDuration),
        frameCutOffset: secondPartFrameFraction,
        duration: +(secondPartDuration-0.01), // TODO: this causes small black spots in video
        startBase: 0,
        leftBackgroundOffset: 0,
        leftShrink: 0,
        row: video.row,
        dirty: true,
        frames: video.frames && video.frames.length ? [secondPartFrame, ...video.frames.slice().splice(firstPartFramesCount+1, secondPartFramesCount)] : [],
        audioFrames: video.audioFrames && video.audioFrames.length ? [secondPartAudioFrame, ...video.audioFrames.slice().splice(firstPartFramesCount+1, secondPartFramesCount)] : [],
        onLoadedCalled: false, // it will go through the loading process
      });

      const fd = await createVideoFd(secondVideo);
      if (fd !== -1) {
        secondVideo.videoFdRef = fd;
        const effectString = secondVideo.getEffectString();
        await Engine.set_effect(fd, secondVideo.scaledWidth as number, secondVideo.scaledHeight as number, effectString)
      }

      const selectedIndex = videos.findIndex((video: Media) => video.id === selectedVideo?.id) ;
      updatedVideos[selectedIndex] = firstVideo;
      updatedVideos.push(secondVideo);

      const originalVideos = videos.map((vid: Media) => vid.deepCopy());
      const resizeCommand = new ResizeVideoCommand(originalVideos as Video[], updatedVideos, (newVideos: Video[]) => {
        setVideos(newVideos)
      });
      CommandHistory.push(resizeCommand);

      // the previously selected video is invalid now
      onLayerSelected(null);
      setVideos(updatedVideos);
    }
  };

  const handleVideoSelect = (_: any, index: number, video: Media) => {
    onLayerSelected(video as Video);
  };

  const handleVideoContainerClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    // if pressed on the grid but not on an item
    if (target.classList.contains('react-grid-layout')) {
      onLayerSelected(null);
    }
  };

  const onSkipFrameClicked = (direction: 'forward' | 'backward'): void => {
    if (!isLoadingVideo) {
      handleSkipFrame(direction);
    }
  }

  const handleZoomChange = (zoom: number) => {
    if (scrollBoxRef.current) {
      setZoomValue(zoom);
      setGridWidth(Math.max(scrollBoxRef.current.clientWidth * zoom, scrollBoxRef.current.clientWidth));
    }
  }

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setMenuPosition({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
    setMenuOpen(true);
  };

  const debouncedMediaUpload = useCallback(
      debounce((name: string, type: string, sha256: string, blobUrl: string) => {
        getSignedUploadUrl(name, type, sha256).then( async (uploadInformation: { generatedName: string, url: string}) => {
          if (uploadInformation.url) {
            const file = await (await fetch(blobUrl)).blob();
            // upload the video
            uploadVideo(uploadInformation.url, file, file.type, () => {});
          }
        })
      }, 2000),
      []
  );

  const pushNewMedia = (media: Media): Media[] => {
    media.row = 0;
    const newVideos = videos.map(vid => {
      vid.row += 1;
      return vid;
    });
    return [...newVideos, media];
  }

  const onTextCreated = async (text: string, style: React.CSSProperties) => {
    const row = videos.reduce((max: number, v: Media) => v.row > max ? v.row : max, -1) + 1;
    const canvas = document.createElement('canvas');
    const [image, textWidth, textHeight] = await createTextImage(canvas, text, style);
    fetch(image).then(async (res) => {
      const imageBlob = await res.blob();
      const textComponent: TextMedia = new TextMedia({name: "Text", text: text, videoRef: canvas, duration : totalDuration ? 0.25 * totalDuration : 10, left: 0, leftBackgroundOffset: 0, rightBackgroundOffset: 0,
        startBase: 0, endBase: 0, isResizing: false, leftShrink: 0, rightShrink: 0, row: row, framesOffset: 0, playOffset: 0,
        effects: [], dirty: false, height: textHeight, width: textWidth, url: image, type: imageBlob.type, sha256: '',
        scaledHeight: textHeight, scaledWidth: textWidth, baseWidth: 0, baseHeight: 0, style: {...style}, textWidth, textHeight,
        x: 0, y: 0, rotation: 0, crop: {left: 0, right: 0, top: 0, bottom: 0}});

      calculateSha256(imageBlob).then((sha256: string) => {
        textComponent.sha256 = sha256;
        debouncedMediaUpload(textComponent.name, textComponent.type, textComponent.sha256, textComponent.url);

        const newVideos = pushNewMedia(textComponent);

        const originalVideos = videos.map((vid: Media) => vid.deepCopy());
        const addVideosCommand = new AddVideoCommand(originalVideos as Video[], newVideos, newVideos.length-1, (newVideos: Video[]) => {
          setVideos(newVideos);
        });

        CommandHistory.push(addVideosCommand);

        setVideos(newVideos);

        if (videos.length === 0) {
          // if it's the only media - set the duration to the media duration
          setTotalDuration(textComponent.duration);
        }
        // select the Text element
        onLayerSelected(textComponent as unknown as Video);
      })
    });
  }

  const onTextStyleChanged = async (text:string, style: React.CSSProperties) => {
    if (selectedVideo === null || !(selectedVideo instanceof TextMedia)) {
      return;
    }
    const textComponent = selectedVideo as TextMedia;
    const canvas = textComponent.videoRef;
    const [image, textWidth, textHeight] = await createTextImage(canvas, text, style, textComponent.scaledWidth as number, textComponent.scaledHeight as number);

    textComponent.url = image;
    textComponent.style = {...style};

    fetch(image).then(async (res) => {
      const imageBlob = await res.blob();
      calculateSha256(imageBlob).then((sha256: string) => {
        textComponent.sha256 = sha256;
        debouncedMediaUpload(textComponent.name, textComponent.type, textComponent.sha256, textComponent.url);
        const newVideos = [...videos];
        textComponent.text = text;
        textComponent.style = style;

        textComponent.baseWidth = Math.max(textComponent.scaledWidth as number, textWidth);
        textComponent.width = Math.max(textComponent.scaledWidth as number, textWidth);
        textComponent.scaledWidth = Math.max(textComponent.scaledWidth as number, textWidth);

        // Cant use the current index because there is a race condition between all the async functions and the initial change
        const selectedIndex = videos.findIndex((video: Media) => video.id === textComponent.id);
        if (selectedIndex !== -1) {
          newVideos[selectedIndex] = textComponent;

          const originalVideos = videos.map((vid: Media) => vid.deepCopy());
          debouncedTextChangeHistoryAction(originalVideos, newVideos, selectedIndex, setVideos);

          setVideos(newVideos);
        }
      })
    });
  }

  const onMediaChanged = async (media: Video, start: number | null, end: number | null) => {
    const selectedIndex = videos.findIndex((video: Media) => video.id === media.id);

    if (selectedIndex === -1) {
      return;
    }
    const curVideo = videos[selectedIndex].deepCopy();
    //const curVideo = videos[selectedIndex];

    if (start !== null && end !== null && end > start) {
      const newLeft = +(start / totalDuration * numColumns).toFixed(3);
      curVideo.left = newLeft;
      // calculate the current total media time (excluding truncated edges)
      const currentTimeLength = (curVideo.duration / totalDuration * numColumns - curVideo.leftShrink - curVideo.rightShrink) / numColumns * totalDuration;
      // calculate the amount of seconds to trunacte from the end
      const truncatedEndTime = currentTimeLength - (end - start);

      // shrink the movie from right according to the number of columns needed to cut
      curVideo.rightShrink += truncatedEndTime / totalDuration * numColumns;
      curVideo.rightBackgroundOffset += truncatedEndTime / totalDuration * numColumns;
      curVideo.endBase += truncatedEndTime / totalDuration * numColumns;
      //console.log(end - start, curVideo.duration, currentTimeLength, speedChange);
    }

    const speedChange = media.speed / curVideo.speed;
    if (speedChange) {
      // if the speed was changed we need to change all related track metadata
      curVideo.duration = media.duration / speedChange;
      curVideo.playOffset /= speedChange;
      curVideo.startBase /= speedChange;
      curVideo.endBase /= speedChange;
      curVideo.leftShrink /= speedChange;
      curVideo.rightShrink /= speedChange;
      curVideo.leftBackgroundOffset /= speedChange;
      curVideo.rightBackgroundOffset /= speedChange;
    }

    curVideo.speed = media.speed;

    curVideo.videoRef.volume = media.volume;
    curVideo.videoRef.playbackRate = media.speed;

    if (curVideo instanceof Video) {
      curVideo.hue = media.hue;
      curVideo.volume = media.volume;
      curVideo.brightness = media.brightness;
      curVideo.opacity = media.opacity;

      const effectString = curVideo.getEffectString();
      if (curVideo.videoFdRef !== null) {
        await Engine.set_effect(curVideo.videoFdRef, curVideo.scaledWidth as number, curVideo.scaledHeight as number, effectString)
      }
    }

    const newVideos = [...videos];
    newVideos[selectedIndex] = curVideo;

    const originalVideos = videos.map((vid: Media) => vid.deepCopy());
    debouncedMediaChangeHistoryAction(originalVideos, newVideos, selectedIndex, ((vids: Media[]) => {
      if (originalVideos[selectedIndex].volume !== (newVideos[selectedIndex] as Video).volume) {
        vids[selectedIndex].videoRef.volume = (vids[selectedIndex] as Video).volume;
      }
      setVideos(vids);
    }));

    setVideos(newVideos)
  }

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleOption1 = () => {
    console.log('Option 1 selected');
  };

  const handleOption2 = () => {
    console.log('Option 2 selected');
  };

  const handleOption3 = () => {
    console.log('Option 3 selected');
  };

  // Expose these methods as we need the EffectSelector to call these when it's a non-mobile
  // layout. in that case the EffectSelector is outside of the component...
  useImperativeHandle(ref, () => ({
    handleAddSubtitles,
    onSubtitlesUpload,
    onTextCreated,
    onTextStyleChanged,
    onMediaChanged,
    onAddMedia
  }));

  return (
    <Box sx={{height: '100%', width: '100%', padding: 0, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'start', overflowX: 'visible', overflowY: 'visible'}}>
      <VideoEditorControls
        currentTime={currentTime}
        maxZoom={totalDuration > 200 ? totalDuration / 40 : totalDuration}
        isMobileLayout={isMobile}
        isRunning={isRunning}
        subtitlesButtonType={(((selectedVideo instanceof Video) as any || selectedVideo instanceof Audio) && (selectedVideo as Video).downloadingSubtitles ?
          'download' :
          (((selectedVideo instanceof Video) as any || selectedVideo instanceof Audio)) && (selectedVideo as Video).subtitlesActive ? 'remove' : 'add')}
        volumeButtonType={(((selectedVideo instanceof Video) as any || selectedVideo instanceof Audio) && (selectedVideo as Video).isMuted) ? 'add' : 'remove'}
        shouldEnableVideoActions={selectedVideo !== null}
        shouldEnableCutAction={
          ((selectedVideo instanceof Video) as any || selectedVideo instanceof Audio) &&
          selectedVideo?.onLoadedCalled &&
          selectedVideo?.uploadComplete
        }
        shouldEnableSubtitlesAction={
          ((selectedVideo instanceof Video) as any || selectedVideo instanceof Audio) &&
          selectedVideo?.hasAudio() &&
          selectedVideo?.onLoadedCalled &&
          selectedVideo?.uploadComplete &&
          (selectedVideo as Video)?.subtitlesLoaded
        }
        shouldEnableAudioAction={selectedVideo?.hasAudio() && !(selectedVideo instanceof Audio)}
        handleCut={() => handleCut(Math.floor((currentTime / totalDuration) * numColumns))}
        handleDelete={handleDelete}
        handleZoomChange={handleZoomChange}
        handleRemoveSound={handleToggleSound}
        handleAddSubtitles={handleAddSubtitles}
        handlePlayPause={handlePlayPause}
        handleSkipFrame={onSkipFrameClicked}
      />
    <Box sx={{minHeight: '40px', height: '40px', pointerEvents: 'none', borderBottom: '1px solid #ddd' }}>
      <FrameSlider
        value={currentTime}
        onChange={onSliderChange}
        min={zoomValue * gridWidth}
        totalDuration={totalDuration || 60}
        maxSliderOffset={maxSliderOffset}
        step={0.01} // Adjust the step based on how fine-grained ou want the control
        numMarkers={(scrollBoxRef.current?.clientWidth || 400) / 50}
        visibleDuration={totalDuration / zoomValue || 60}
        scrollPos={sliderScrollPos}
        sliderHeight={1000} // large height to let it overflow from bottom (hidden overflow stops it from affecting layout)
        padding={layersPadding}
        aria-labelledby="video-slider"
      />
    </Box>
    <Box sx={{height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'start', overflowX: 'hidden', overflowY: 'hidden'}}>
      <Box ref={scrollBoxRef} sx={{ height: '100%', overflowX:'auto', overflowY: 'auto'}}>
        <Box
            ref={containerRef}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(event: React.DragEvent<HTMLDivElement>) => {
              event.preventDefault();
              onAddMedia(event.dataTransfer.files[0])
            }}
            sx={{
                display: 'flex',
                userSelect: 'none',
                flexDirection: 'column',
                justifyContent: 'space-around',
                width: gridWidth || '100%',
                height: '100%',
                overflowY: 'hidden',
                overflowX: 'auto',
            }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, backgroundColor: "#ffffff", overflowY: 'hidden', overflowX: 'hidden'}}>
            <Box
              onContextMenu={handleContextMenu}
              onMouseDown={handleVideoContainerClick}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                paddingLeft: `${layersPadding}px`,
                paddingRight: `${layersPadding}px`,
                overflowX: 'hidden',
                overflowY: 'auto',
                }}
              >
                {isMobile && (videos.length === 0) ? (
                  <MediaAddComponent onAddMedia={onAddMedia}/>
                ) : (
              <ResponsiveGridLayout
                ref={gridRef}
                className="layout"
                cols={numColumns}
                rowHeight={60}
                preventCollision={true}
                resizeHandles={["e", "w"]}
                compactType={null}
                isDraggable={true}
                margin={[0, 2]}
                onDragStart={handleDragStart}
                onDragStop={handleDragStop}
                onResizeStop={handleResizeStop}
                onResize={handleResize}
                onResizeStart={handleResizeStart}
                onLayoutChange={handleLayoutChange}
                style={{
                  position:'relative',
                  height: '100%',
                  width: gridWidth || '100%',
                  //boxShadow: 'inset 0 0 0 1px #ddd',
                  //backgroundImage: `linear-gradient(to bottom,
                  //  rgba(230, 230, 230, 0.8) 0px,
                  //  rgba(230, 230, 230, 0.8) 60px,
                  //  rgba(255, 255, 255, 0) 60px,
                  //  rgba(255, 255, 255, 0) 125px)`, // Soft gradient shading
                  //backgroundSize: '100% 125px' // Adjust size based on rowHeight
                }}
                draggableHandle=".drag-handle"
              >
                {videos.map((video: Media, index: number) => (
                  <Box
                    className={`drag-handle video-track ${video.isResizing ? 'resizing' : ''} ${selectedVideo?.id === video.id ? 'selected' : 'drag-handle'}`}
                    key={video.id}
                    data-grid={{
                      i: `${video.id}`,
                      x: video.left,
                      y: video.row,
                      w: (video.duration / totalDuration) * numColumns - video.leftShrink - video.rightShrink,
                      h: 1,
                      minW: minGridItemPixelSize / columnWidth,
                      maxW: video instanceof Video ? (video.duration / totalDuration) * numColumns : numColumns,
                      isBounded: true,
                      isDraggable: isMobile ? selectedVideo?.id === video.id : true // for mobile, only allow dragging once selected. for desktop can drag at all times
                    }}
                    sx={{
                      overflow: video.isResizing ? 'visible' : 'hidden',
                    }}
                  >
                    <div
                      onClick={(event: any) => handleVideoSelect(event, index, video)}
                      onTouchStart={(event: any) => handleVideoSelect(event, index, video)}
                      onMouseDown={(event: any) => handleVideoSelect(event, index, video)}
                    >
                      <VideoFramesComponent
                        video={video}
                        numFrames={video instanceof TextMedia ? 1 : Math.ceil(numFrames * (video.duration / totalDuration))}
                        leftBackgroundOffset={video.leftBackgroundOffset * columnWidth}
                        rightBackgroundOffset={video.rightBackgroundOffset * columnWidth}
                        size={(video.duration / totalDuration) * numColumns * columnWidth}
                        hasVideoComponent={video.hasVideo()}
                        hasAudioComponent={video.audioEnabled()}
                        onLoadedVideo={(vid: Video, frames: any[], audioFrames: any[]) => onLoadedVideo(vid, frames, audioFrames, index)}
                        onVideoUploadComplete={onVideoUploadComplete}
                        preloadedFrames={video.frames.length ? video.frames : undefined}
                        preloadedAudioFrames={video.audioFrames.length ? video.audioFrames : undefined}
                      />
                    </div>
                  </Box>
                ))}
              </ResponsiveGridLayout>
              )}
            </Box>
          </Box>
          <video ref={videoRef} style={{ display: 'none' }} />
          {videoFileLoadingInfo && (
          <Box sx={{ position: 'absolute', bottom: isMobile ? 0 : 0, left: 0, zIndex: 1, width: '100%', background: '#F5F5F5' }}>
            <Typography variant="body2" align="left">Loading {videoFileLoadingInfo.name} ({(videoFileLoadingInfo.size / (1024 * 1024)).toFixed(2)} MB)</Typography>
            <LinearProgress variant="indeterminate" />
          </Box>
          )}
          {/*
          <RightClickMenu
            open={menuOpen}
            position={menuPosition}
            onClose={handleClose}
            onOption1={handleOption1}
            onOption2={handleOption2}
            onOption3={handleOption3}
          />
          */}
          {isDragging && (
            <div
              ref={dragRef}
              onDragLeave={handleDragLeave}
              className="drop-overlay"
            >
              <div className="drop-content" style={{pointerEvents: 'none'}}>
                <CloudUpload className="upload-icon" style={{fontSize: 120, pointerEvents: 'none'}} />
                <h2 className="drop-title" style={{pointerEvents: 'none'}}>Drop your file here</h2>
                <p className="drop-subtitle" style={{pointerEvents: 'none'}}>or drag and drop anywhere on the screen</p>
              </div>
            </div>
          )}
        </Box>
      </Box>
    </Box>
      <Box sx={{width: '100%', borderTop: '1px solid #ddd' }}>
        {isMobile && (
        <EffectSelector
          videoStart={(selectedVideo !== null) ? ((selectedVideo.left) / numColumns) * totalDuration : null}
          videoEnd={(selectedVideo !== null) ? ((selectedVideo.left + (selectedVideo.duration / totalDuration) * numColumns - selectedVideo.rightShrink - selectedVideo.leftShrink) / numColumns * totalDuration) : null}
          selectedVideo={(selectedVideo !== null) ? selectedVideo : null}
          canvasBackgroundColor={canvasBackgroundColor}
          onSelect={onEffectSelected}
          onAddMedia={onAddMedia}
          onMediaChanged={onMediaChanged}
          onChangeBackground={onChangeBackground}
          onTextCreated={onTextCreated}
          onSubtitlesGenerate={handleAddSubtitles}
          onSubtitlesUpload={onSubtitlesUpload}
          onTextStyleChanged={onTextStyleChanged}
          isMobileLayout={isMobile} />) }
      </Box>
    </Box>
  );
});

export { VideoLayers, type MediaLayer };
